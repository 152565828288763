import React, { useEffect, useState } from "react"; import Header
  from "../components/Header"; import Footer from "../components/Footer"; import
vevyelinkfive from "../assets/img/vevyelinkfive.png"; import vevyelinkfour
  from "../assets/img/vevyelinkfour.png"; import vevyelinkthree
  from "../assets/img/vevyelinkthree.png"; import vevyelinktwo
  from "../assets/img/vevyelinktwo.png"; import vevyelinkone
  from "../assets/img/vevyelinkone.png"; import ilevrolinksix
  from "../assets/img/ilevrolinksix.png"; import ilevrolinkfive
  from "../assets/img/ilevrolinkfive.png"; import ilevrolinkfour
  from "../assets/img/ilevrolinkfour.png"; import ilevrolinkthree
  from "../assets/img/ilevrolinkthree.png"; import ilevrolinktwo
  from "../assets/img/ilevrolinktwo.png"; import ilevrolinkone
  from "../assets/img/ilevrolinkone.png"; import verkazialinkfour
  from "../assets/img/verkazialinkfour.png"; import verkazialinkthree
  from "../assets/img/verkazialinkthree.png"; import verkazialinktwo
  from "../assets/img/verkazialinktwo.png"; import verkazialinkone
  from "../assets/img/verkazialinkone.png"; import Linknine
  from "../assets/img/Linknine.png"; import Linkeight
  from "../assets/img/Linkeight.png"; import Linkseven
  from "../assets/img/Linkseven.png"; import Linksix
  from "../assets/img/Linksix.png"; import Linkfive
  from "../assets/img/Linkfive.png"; import Linkfour
  from "../assets/img/Linkfour.png"; import Linkthree
  from "../assets/img/Linkthree.png"; import Linktwo
  from "../assets/img/Linktwo.png"; import Vevyelinksix
  from "../assets/img/vevyelinksix.png"; import Vevyelinkseven
  from "../assets/img/vevyelinkseven.png"; import Linkone

  from "../assets/img/Linkone.png"; import axios from "axios";

const ClinicalData = () => {
  const [data, setData] = useState(null); const
    [tabDesc, setTabDesc] = useState(''); const [modalURL, setModalURL] = useState
      (''); const tabs = [
        // { id: "all", label: "All", desc: "" },
        { id: "vevye", label: "Vevye", desc: "Vevye (cyclosporine ophthalmic solution 0.1%) is a calcineurin inhibitor immunosuppressant indicated for the treatment of the signs and symptoms of dry eye disease." },
        // { id: "iheezo", label: "IHEEZO", desc: "IHEEZO (chloroprocaine HCl
        // ophthalmic gel 3%) is an ester anesthetic indicated for ocular surface
        // anesthesia." },
        { id: "triesence", label: "Triesence", desc: "Triesence (triamcinolone acetonide injectable suspension 40mg/ml) is a synthetic corticosteroid indicated for the treatment of the following ophthalmic diseases: sympathetic ophthalmia, temporal arteritis, uveitis, and ocular inflammatory conditions unresponsive to topical corticosteroids as well as for visualization during vitrectomy." },
        { id: "ilevro", label: "Ilevro", desc: "Ilevro (nepafenac ophthalmic suspension 0.3%) is a nonsteroidal, anti-inflammatory prodrug indicated for the treatment of pain and inflammation associated with cataract surgery." },
        { id: "verkazia", label: "Verkazia", desc: "Verkazia (cyclosporine ophthalmic emulsion 0.1%) is a calcineurin inhibitor immunosuppressant indicated for the treatment of vernal keratoconjunctivitis in children and adults." },
        // { id: "flarex", label: "Flarex", desc: "Flarex (fluorometholone acetate
        // ophthalmic suspension 0.1%) is indicated for use in the treatment of
        // steroid responsive inflammatory conditions of the palpebral and bulbar
        // conjunctiva, cornea, and anterior segment of the eye." },
        // { id: "tobradex", label: "Tobradex ST", desc: "Tobradex ST
        // (tobramycin and dexamethasone ophthalmic suspension 0.3%/0.05%) is a
        // topical antibiotic and corticosteroid combination for steroid-responsive
        // inflammatory ocular conditions for which a corticosteroid is indicated
        // and where superficial bacterial ocular infections or a risk of bacterial
        // ocular infections exists." },
      ];

  useEffect(() => {
    const getData = async () => {
      try {
        axios.get("../data.json")
        .then(response => {
          console.log(">>>>>>>>>", response);
          setData(response?.data);
        }).catch((error) => {
          console.error
          ("Error:", error);
        });
      } catch (error) { console.error('Error fetching data:', error); }
    }; getData(); setTabDesc(tabs[0]?.desc); $
      ('.filterTab li a').click(function () {
        var ourClass = $(this).attr
          ('class'); let descValue = $(this).attr('data-desc'); setTabDesc
            (descValue); $('.filterTab li').removeClass('active'); $(this).parent
              ().addClass('active');

        if (ourClass == 'all') {
          $('#postId').children('.rcnt-post').show
          ();
        } else {
          $('#postId').children('div:not(.' + ourClass + ')').hide
          (); $('#postId').children('div.' + ourClass).show();
        } return false;
      });
    $('#myModal').on('shown.bs.modal', function () {
      $('#myInput').trigger
      ('focus')
    })
  }, []);

  const renderImgs = (img) => {
    if (img?.includes("vevyelinkfive")) { return vevyelinkfive; } else if (img?.includes("Vevyelinksix")) { return Vevyelinksix; } else if (img?.includes("Vevyelinkseven")) { return Vevyelinkseven; } else if (img?.includes("vevyelinkfour")) { return vevyelinkfour; } else if (img?.includes("vevyelinkthree")) { return vevyelinkthree; } else if (img?.includes("vevyelinktwo")) { return vevyelinktwo; } else if (img?.includes("vevyelinkone")) { return vevyelinkone; } else if (img?.includes("ilevrolinksix")) { return ilevrolinksix; } else if (img?.includes("ilevrolinkfive")) { return ilevrolinkfive; } else if (img?.includes("ilevrolinkfour")) { return ilevrolinkfour; } else if (img?.includes("ilevrolinkthree")) { return ilevrolinkthree; } else if (img?.includes("ilevrolinktwo")) { return ilevrolinktwo; } else if (img?.includes("ilevrolinkone")) { return ilevrolinkone; } else if (img?.includes("verkazialinkfour")) { return verkazialinkfour; } else if (img?.includes("verkazialinkthree")) { return verkazialinkthree; } else if (img?.includes("verkazialinktwo")) { return verkazialinktwo; } else if (img?.includes("verkazialinkone")) { return verkazialinkone; } else if (img?.includes("Linknine")) { return Linknine; }
    else if (img?.includes("Linkeight")) { return Linkeight; } else if
      (img?.includes("Linkseven")) { return Linkseven; } else if (img?.includes
        ("Linksix")) { return Linksix; } else if (img?.includes("Linkfive")) { return Linkfive; } else if (img?.includes("Linkfour")) { return Linkfour; } else if
          (img?.includes("Linkthree")) { return Linkthree; } else if (img?.includes
            ("Linktwo")) { return Linktwo; } else if (img?.includes("Linkone")) { return Linkone; } 
  }

  return (<div className="main"> <Header /> <section className="pagetitle">
    <div className="container"> <div className="pg-tle-txt"> <h2>Clinical
      data</h2> <p> Because the conscientious, explicit and judicious use of
        evidence in making decisions about patient care matters. </p> </div> </div>
  </section> <section className="RecentPPost"> <div className="container"> <div
    className="filterTab"> <ul>{tabs && tabs?.map((tab) => (<li key=
      {tab?.id} className={tab?.id === "vevye" ? "active" : ""}> <a
        href="javascript:void(0)" className={tab.id} data-desc={tab?.desc}>
        {tab.label} </a> </li>))} </ul> </div> <div className="tabTitle"> <p>
          {tabDesc}</p> </div>

    <div className="tabPostbox"> <div className="row" id="postId">
      {data && data?.tabsBox && data?.tabsBox?.map((item, ind) => {
        return (<div className={`col-sm-4 rcnt-post ${item?.label}`} key=
          {ind} style={{ display: item?.label === "vevye" ? "block" : "none" }}>
          <div className="postbox"> <div className="postImg"> <img src=
            {renderImgs(item?.img)} alt="Recent Post" /> </div> <div
              className="postTtle"> <p>{item?.subHead}</p> <h2>
                {item?.head}</h2> </div>{item?.desc && <div className="postPara"> <p>
                  {item?.desc}</p> </div>} <div className="postLink"> <div
                    className="horline"></div> <a href="javascript:void(0);" onClick={
                      () => setModalURL(item?.learnMoreURL)} data-toggle="modal"
                      data-target="#exampleModal">Learn More</a> </div> <div
                        className="postUser">{item?.postUsr} </div> </div> </div>);
      })}
    </div> </div> </div> </section> <Footer />
    {/* Bootstrap v4 Popup Modal */}
    <div className="modal fade" id="exampleModal" tabindex="-1"
      aria-labelledby="exampleModalLabel" aria-hidden="true"> <div
        className="modal-dialog modal-dialog-centered"> <div
          className="modal-content"> <div className="modal-header"> <h5
            className="modal-title" id="exampleModalLabel">You are now leaving Harrow
            Science</h5> <button type="button" className="close" data-dismiss="modal"
              aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
          </div> <div className="modal-body"> <h6>DISCLAIMER</h6> <p>The linked
            website contains information created, published, maintained, or otherwise
            posted by an organization independent of Harrow, Inc. Views and opinions
            expressed by this website do not necessarily represent the views of
            Harrow, Inc.</p> <h6>OFF-LABEL STATEMENT</h6> <p>The following website may contain information or discussion of uses of medications, devices or agents not indicated by the FDA. Views expressed are solely those of the researcher or physician and are not necessarily those of Harrow, Inc.</p>

            <p>Please click “Continue” to leave this website and proceed to the selected website site.</p> </div> <div className="modal-footer">
            <button onClick={() => window.open(modalURL, "_blank")} type="button"
              className="btn btn-success" data-dismiss="modal">Continue</button>
            <button type="button" className="btn btn-danger"
              data-dismiss="modal">Cancel</button> </div> </div> </div> </div>
  </div>);
};

export default ClinicalData;
