import React from "react";
import Header from "../components/Header";
import underConstructionImg from "../assets/img/under-construction.svg";
import Footer from "../components/Footer";

const CharitableGrantRequest = () => {
  return (
    <div className="main">
      <Header />
      <section className="construction">
        <div className="container">
          <div className="constructionImg">
            <img src={underConstructionImg} alt="Under Construction" />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default CharitableGrantRequest;
