import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

const InvestigatorInitiatedTrials = () => {
  const [submitText, setSubmitText] = useState('Submit');
  const [formStatus, setFormStatus] = useState('');
  const initialValues = {
    fullName: "",
    phone: "",
    email: "",
    city: "",
    state: "",
    country: "",
    product: "",
    indication: "",
    studyTitle: "",
    rationalfortrial: "",
    primaryObjective: "",
    clinicalResearchExp: "",
    involved_studies: "",
    dedicatedResearchCoord: "",
    file: null,
  };
  const validationSchema = Yup.object({
    fullName: Yup.string().required("Required"),
    phone: Yup.string().matches(/^[0-9]{10}$/, 'Invalid phone number').required("Required"),
    email: Yup.string().email("Invalid email address").required("Required"),
    city: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    // country: Yup.string().required("Required"),
    product: Yup.string().required("Required"),
    indication: Yup.string().required("Required"),
    studyTitle: Yup.string().required("Required"),
    rationalfortrial: Yup.string().required("Required"),
    primaryObjective: Yup.string().required("Required"),
    clinicalResearchExp: Yup.string().required("Required"),
    involved_studies: Yup.string().required("Required"),
    dedicatedResearchCoord: Yup.string().required("Required"),
    file: Yup.mixed().required('File is required')
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values,{resetForm}) => {
      setFormStatus("");
      setSubmitText("Submitting...");
      let data = {
        full_name: values.fullName,
        phone_no: values.phone,
        email: values.email,
        city: values.city,
        state: values.state,
        country: values.country,
        product: values.product,
        indication: values.indication,
        title_of_study: values.studyTitle,
        rationale: values.rationalfortrial,
        endpoint: values.primaryObjective,
        years_experience: values.clinicalResearchExp,
        involved_studies: values.involved_studies,
        coordinator: values.dedicatedResearchCoord,
        resume: values.file,
      };
      let formData = new FormData();

      for (let key in data) {
        formData.append(key, data[key]);
      }

      await axios.post("https://api.iheezo.com/harrow-science/liit.php", formData)
        .then((res) => {
          console.log("res:::",res);
          setSubmitText("Submit");
          setFormStatus("success");
          $('#exampleModal').modal('show');
          resetForm();
        }).catch((err) => {
          console.error("Error:",err);
          setSubmitText("Submit");
          setFormStatus("failed");
          $('#exampleModal').modal('show');
        });
    },
  });
  const { handleBlur, handleChange, handleSubmit, values, errors, touched } = formik;

  return (
    <div className="main">
      <Header />
      <section className="pagetitle">
        <div className="container">
          <div className="pg-tle-txt">
            <h2>Submit an Investigator-Initiated Trial Proposal</h2>
            <p>Please fill out the following form to submit your proposal.</p>
          </div>
        </div>
      </section>
      <section className="medicalinfo investigator">
        <div className="container">
          <div className="investHeader">
            <h2 className="firsth2">Use the form below to submit a proposal</h2>
            <p>
              At Harrow, we believe in the need to support independent research
              conducted by qualified individuals that address important
              unanswered questions and improve patient care. Submission of a
              proposal does not guarantee funding. The committee will evaluate
              proposals for scientific merit, alignment with our medical
              strategy, and available funding.
            </p>
            <p>
              Please fill out all fields with as much detail as possible so we
              may better evaluate your proposal.
            </p>
            <p>
              If your proposal is accepted, you will be responsible for study
              design, initiation, management, data analysis, monitoring,
              reporting, writing, and publishing.
            </p>

            <h2 className="lasth2">Please provide your information below.</h2>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>
                    Investigator Full Name <span>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="fullName"
                    placeholder=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.fullName}
                  />
                  {touched.fullName && errors.fullName && (
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {errors.fullName}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>
                    Phone <span>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="tel"
                    name="phone"
                    placeholder=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                    maxLength={10}
                  />
                  {touched.phone && errors.phone && (
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {errors.phone}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>
                    Email <span>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    placeholder=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {touched.email && errors.email && (
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {errors.email}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>
                    City <span>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="city"
                    placeholder=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                  />
                  {touched.city && errors.city && (
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {errors.city}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>State, Province or Territory <span>*</span></label>
                  <div className="select">
                    <select
                      className="form-control"
                      name="state"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.state}
                    >
                      <option value=""></option>
                      <option value="AL">AL</option>
                      <option value="AK">AK</option>
                      <option value="AR">AR</option>
                      <option value="AZ">AZ</option>
                      <option value="CA">CA</option>
                      <option value="CO">CO</option>
                      <option value="CT">CT</option>
                      <option value="DC">DC</option>
                      <option value="DE">DE</option>
                      <option value="FL">FL</option>
                      <option value="GA">GA</option>
                      <option value="HI">HI</option>
                      <option value="IA">IA</option>
                      <option value="ID">ID</option>
                      <option value="IL">IL</option>
                      <option value="IN">IN</option>
                      <option value="KS">KS</option>
                      <option value="KY">KY</option>
                      <option value="LA">LA</option>
                      <option value="MA">MA</option>
                      <option value="MD">MD</option>
                      <option value="ME">ME</option>
                      <option value="MI">MI</option>
                      <option value="MN">MN</option>
                      <option value="MO">MO</option>
                      <option value="MS">MS</option>
                      <option value="MT">MT</option>
                      <option value="NC">NC</option>
                      <option value="NE">NE</option>
                      <option value="NH">NH</option>
                      <option value="NJ">NJ</option>
                      <option value="NM">NM</option>
                      <option value="NV">NV</option>
                      <option value="NY">NY</option>
                      <option value="ND">ND</option>
                      <option value="OH">OH</option>
                      <option value="OK">OK</option>
                      <option value="OR">OR</option>
                      <option value="PA">PA</option>
                      <option value="RI">RI</option>
                      <option value="SC">SC</option>
                      <option value="SD">SD</option>
                      <option value="TN">TN</option>
                      <option value="TX">TX</option>
                      <option value="UT">UT</option>
                      <option value="VT">VT</option>
                      <option value="VA">VA</option>
                      <option value="WA">WA</option>
                      <option value="WI">WI</option>
                      <option value="WV">WV</option>
                      <option value="WY">WY</option>
                    </select>
                  </div>
                  {/* <input
                    className="form-control"
                    type="text"
                    name="state"
                    placeholder=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.state}
                  /> */}
                  {touched.state && errors.state && (
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {errors.state}
                    </span>
                  )}
                </div>
              </div>
              {/*<div className="col-sm-6">
                <div className="form-group">
                  <label>Country <span>*</span></label>
                  <div className="select">
                    <select
                      className="form-control"
                      name="country"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.country}
                    >
                      <option value=""></option>
                      {/* <option value="India">India</option> * /}
                      <option value="USA">USA</option>
                    </select>
                    <div className="svgBG">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                      >
                        <path
                          d="M9.99974 1.00576C9.94329 1.18976 9.81531 1.31973 9.68631 1.45154C8.27012 2.89612 6.8547 4.34174 5.44106 5.7892C5.26761 5.96693 5.07041 6.04993 4.8331 5.96876C4.74446 5.93848 4.6571 5.87846 4.59043 5.81034C3.12008 4.31277 1.65203 2.81312 0.185008 1.31191C-0.0614971 1.05979 -0.0604746 0.733289 0.180922 0.478823C0.294084 0.35955 0.406735 0.238712 0.530371 0.131183C0.738815 -0.049944 1.02772 -0.0408085 1.23591 0.141885C1.26784 0.170072 1.29798 0.200607 1.32787 0.231143C2.52208 1.45127 3.71654 2.67114 4.91024 3.8918C4.93783 3.91998 4.96031 3.95313 5.02877 4.00611C5.04742 3.97088 5.05942 3.92886 5.08599 3.90171C6.29067 2.6688 7.49638 1.43692 8.70259 0.205567C8.92764 -0.024105 9.23775 -0.0700395 9.44823 0.118918C9.66281 0.311529 9.89859 0.492917 10 0.786009V1.0055L9.99974 1.00576Z"
                          fill="#80ABA5"
                        />
                      </svg>
                    </div>
                  </div>
                  {touched.country && errors.country && (
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {errors.country}
                    </span>
                  )}
                </div>
              </div>*/}
              <div className="col-sm-6">
                <div className="form-group">
                  <label>
                    Product <span>*</span>
                  </label>
                  <div className="select">
                    <select
                      className="form-control"
                      name="product"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.product}
                    >
                      <option value=""></option>
                      <option value="Iheezo">Iheezo</option>
                      <option value="Ilevro">Ilevro</option>
                      <option value="Triesence">Triesence</option>
                      <option value="Vevye">Vevye</option>
                      <option value="Verkazia">Verkazia</option>
                      <option value="Vigamox">Vigamox</option>
                      <option value="Other">Other</option>
                    </select>

                    <div className="svgBG">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                      >
                        <path
                          d="M9.99974 1.00576C9.94329 1.18976 9.81531 1.31973 9.68631 1.45154C8.27012 2.89612 6.8547 4.34174 5.44106 5.7892C5.26761 5.96693 5.07041 6.04993 4.8331 5.96876C4.74446 5.93848 4.6571 5.87846 4.59043 5.81034C3.12008 4.31277 1.65203 2.81312 0.185008 1.31191C-0.0614971 1.05979 -0.0604746 0.733289 0.180922 0.478823C0.294084 0.35955 0.406735 0.238712 0.530371 0.131183C0.738815 -0.049944 1.02772 -0.0408085 1.23591 0.141885C1.26784 0.170072 1.29798 0.200607 1.32787 0.231143C2.52208 1.45127 3.71654 2.67114 4.91024 3.8918C4.93783 3.91998 4.96031 3.95313 5.02877 4.00611C5.04742 3.97088 5.05942 3.92886 5.08599 3.90171C6.29067 2.6688 7.49638 1.43692 8.70259 0.205567C8.92764 -0.024105 9.23775 -0.0700395 9.44823 0.118918C9.66281 0.311529 9.89859 0.492917 10 0.786009V1.0055L9.99974 1.00576Z"
                          fill="#80ABA5"
                        />
                      </svg>
                    </div>
                  </div>
                  {touched.product && errors.product && (
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {errors.product}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label>Indication <span>*</span></label>
                  <input
                    type="text"
                    className="form-control"
                    name="indication"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.indication}
                  />
                  {touched.indication && errors.indication && (
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {errors.indication}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label>
                    Title of Study <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="studyTitle"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.studyTitle}
                  />
                  {touched.studyTitle && errors.studyTitle && (
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {errors.studyTitle}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label>
                    Rationale for Trial <span>*</span>
                  </label>
                  <div className="textArea">
                    <input
                      type="text"
                      className="form-control"
                      name="rationalfortrial"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.rationalfortrial}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="14"
                      viewBox="0 0 15 14"
                      fill="none"
                    >
                      <line
                        x1="0.667307"
                        y1="11.2161"
                        x2="12.5475"
                        y2="0.626781"
                        stroke="#DCDCDC"
                      />
                      <line
                        x1="5.08924"
                        y1="11.5481"
                        x2="12.3706"
                        y2="5.05784"
                        stroke="#DCDCDC"
                      />
                    </svg>
                    {touched.rationalfortrial && errors.rationalfortrial && (
                      <span style={{ fontSize: "12px", color: "red" }}>
                        {errors.rationalfortrial}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Primary Objective/Endpoint <span>*</span></label>
                  <div className="textArea">
                    <input
                      type="text"
                      className="form-control"
                      name="primaryObjective"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.primaryObjective}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="14"
                      viewBox="0 0 15 14"
                      fill="none"
                    >
                      <line
                        x1="0.667307"
                        y1="11.2161"
                        x2="12.5475"
                        y2="0.626781"
                        stroke="#DCDCDC"
                      />
                      <line
                        x1="5.08924"
                        y1="11.5481"
                        x2="12.3706"
                        y2="5.05784"
                        stroke="#DCDCDC"
                      />
                    </svg>
                    {touched.primaryObjective && errors.primaryObjective && (
                      <span style={{ fontSize: "12px", color: "red" }}>
                        {errors.primaryObjective}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label>
                    How many years of clinical research experience do you have? <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="clinicalResearchExp"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.clinicalResearchExp}
                  />
                  {touched.clinicalResearchExp &&
                    errors.clinicalResearchExp && (
                      <span style={{ fontSize: "12px", color: "red" }}>
                        {errors.clinicalResearchExp}
                      </span>
                    )}
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label>
                    How many clinical studies are you currently involved in? <span>*</span>
                  </label>
                  <div className="textArea">
                    <input
                      type="text"
                      className="form-control"
                      name="involved_studies"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.involved_studies}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="14"
                      viewBox="0 0 15 14"
                      fill="none"
                    >
                      <line
                        x1="0.667307"
                        y1="11.2161"
                        x2="12.5475"
                        y2="0.626781"
                        stroke="#DCDCDC"
                      />
                      <line
                        x1="5.08924"
                        y1="11.5481"
                        x2="12.3706"
                        y2="5.05784"
                        stroke="#DCDCDC"
                      />
                    </svg>
                    {touched.involved_studies && errors.involved_studies && (
                      <span style={{ fontSize: "12px", color: "red" }}>
                        {errors.involved_studies}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label>
                    Do you have a dedicated research coordinator(s)? <span>*</span>
                  </label>
                  <div className="textArea">
                    <input
                      type="text"
                      className="form-control"
                      name="dedicatedResearchCoord"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.dedicatedResearchCoord}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="14"
                      viewBox="0 0 15 14"
                      fill="none"
                    >
                      <line
                        x1="0.667307"
                        y1="11.2161"
                        x2="12.5475"
                        y2="0.626781"
                        stroke="#DCDCDC"
                      />
                      <line
                        x1="5.08924"
                        y1="11.5481"
                        x2="12.3706"
                        y2="5.05784"
                        stroke="#DCDCDC"
                      />
                    </svg>
                    {touched.dedicatedResearchCoord &&
                      errors.dedicatedResearchCoord && (
                        <span style={{ fontSize: "12px", color: "red" }}>
                          {errors.dedicatedResearchCoord}
                        </span>
                      )}
                  </div>
                </div>
              </div>
            </div>

            <div className="enq">
              <div className="resume">
                <p>
                  <b>Resume</b> <br />
                  Please be sure to include an updated resume <span>*</span>
                </p>
              </div>
              <div className="uploadResume">
              <label htmlFor="file">Upload resume</label>
                <input
                  type="file"
                  id="file"
                  name="file"
                  onChange={(event) => {
                    formik.setFieldValue("file", event.target.files[0]);
                    event.target.value = null;
                  }}
                  onBlur={formik.handleBlur}
                  
                />
                <div className="acceptedFile">DOC, DOCX, PDF (2MB)</div>
                {touched.file && errors.file && (
                  <span style={{ fontSize: "12px", color: "red" }}>
                    {errors.file}
                  </span>
                )}
              </div>
              <div className="submitbtn">
                <input
                  className="form-control"
                  type="submit"
                  value={submitText}
                />
              </div>
            </div>
          </form>
        </div>
      </section>
      <Footer />
      {/* Bootstrap v4 Popup Modal */}
      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Form submittion status</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
            {formStatus === "success" ? <h6 className="text-success">Form submitted successfully</h6> : <h6 className="text-danger">Form submition failed</h6>}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-success" data-dismiss="modal">OK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestigatorInitiatedTrials;
