import React, { useEffect } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import Home from './pages/Home';
import ClinicalData from './pages/ClinicalData';
import CharitableGrantRequest from './pages/CharitableGrantRequest';
import InvestigatorInitiatedTrials from './pages/InvestigatorInitiatedTrials';
import MedicalInfoRequests from './pages/MedicalInfoRequests';
import SinglePage from './pages/SinglePage';

const App = () => {
  const { pathname } = useLocation();

  const ScrollToTop = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return null;
  };

  const Routes = ()=> {
    const element = useRoutes([
      { path: "/", element: <Home /> },
      { path: "/clinical-data", element: <ClinicalData /> },
      { path: "/charitable-grant-request", element: <CharitableGrantRequest /> },
      { path: "/investigator-initiated-trials", element: <InvestigatorInitiatedTrials /> },
      { path: "/medical-info-requests", element: <MedicalInfoRequests /> },
      { path: "/medinfo", element: <MedicalInfoRequests /> },
      { path: "/single-page", element: <SinglePage /> },
    ]);
    return element;
  }
  
  return (
    <>
      <ScrollToTop />
      <Routes/>
    </>
  )
}

export default App;
