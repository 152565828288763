import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";

const MedicalInfoRequests = () => {
  const [submitText, setSubmitText] = useState('Submit');
  const [formStatus, setFormStatus] = useState('');
  const initialValues = {
    rhp_name: "",
    practice_name: "",
    city: "",
    state: "",
    zip: "",
    phone_no: "",
    contact_time: "",
    email: "",
    hcp: "",
    response: "",
    inquiry: "",
    check: false,
    date: "",
  };
  const validationSchema = Yup.object({
    rhp_name: Yup.string().required("Required"),
    practice_name: Yup.string().required("Required"),
    phone_no: Yup.string().matches(/^[0-9]{10}$/, 'Invalid phone number').required("Required"),
    email: Yup.string().email("Invalid email address").required("Required"),
    city: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    zip: Yup.string().matches(/^[0-9]{3,5}$/, 'Invalid zip code').required("Required"),
    hcp: Yup.string().required("Required"),
    response: Yup.string().required("Required"),
    // inquiry: Yup.string().required("Required"),
    check: Yup.boolean().oneOf([true], 'Required'),
    // date: Yup.string().required("Required"),
    contact_time: Yup.string().required("Required"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values,{resetForm}) => {
      setFormStatus("");
      setSubmitText("Submitting...");
      let data = {
        rhp_name: values.rhp_name,
        practice_name: values.practice_name,
        city: values.city,
        state: values.state,
        zip: values.zip,
        phone_no: values.phone_no,
        contact_time: values.contact_time,
        email: values.email,
        hcp: values.hcp,
        response: values.response,
        inquiry: values.inquiry,
        check: values.check,
        date: values.date,
      };

      await axios.post("https://api.iheezo.com/harrow-science/medinfo.php", data)
        .then((res) => {
          console.log("res:::",res);
          setSubmitText("Submit");
          setFormStatus("success");
          $('#exampleModal').modal('show');
          resetForm();
        }).catch((err) => {
          console.error("Error:",err);
          setSubmitText("Submit");
          setFormStatus("failed");
          $('#exampleModal').modal('show');
        });
    },
  });
  const { handleBlur, handleChange, handleSubmit, values, errors, touched } = formik;

  return (
    <div className="main">
      <Header />
      <section className="pagetitle">
        <div className="container">
          <div className="pg-tle-txt">
            <h2>Medical Information Request Form</h2>
            <p>
              Please fill out the following form to request medical information.
            </p>
          </div>
        </div>
      </section>
      <section className="medicalinfo">
        <div className="container">
          <h2>Contact Information of Requesting Healthcare Professional</h2>
          <p class="queryform-p">
          This form is reserved for healthcare providers (HCPs) that have prescribing rights in the United States. Patients and HCPs without prescribing rights should consult their physician to have questions answered.
          </p>

          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>
                    Name of Requesting Healthcare Professional <span>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="rhp_name"
                    placeholder=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.rhp_name}
                  />
                  {touched.rhp_name && errors.rhp_name && (
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {errors.rhp_name}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>
                    Institution Name/Practice Name <span>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="practice_name"
                    placeholder=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.practice_name}
                  />
                  {touched.practice_name && errors.practice_name && (
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {errors.practice_name}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>
                    City <span>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="city"
                    placeholder=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                  />
                  {touched.city && errors.city && (
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {errors.city}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>
                    State <span>*</span>
                  </label>
                  <div className="select">
                    <select
                      className="form-control"
                      name="state"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.state}
                    >
                      <option value=""></option>
                      <option value="AL">AL</option>
                      <option value="AK">AK</option>
                      <option value="AR">AR</option>
                      <option value="AZ">AZ</option>
                      <option value="CA">CA</option>
                      <option value="CO">CO</option>
                      <option value="CT">CT</option>
                      <option value="DC">DC</option>
                      <option value="DE">DE</option>
                      <option value="FL">FL</option>
                      <option value="GA">GA</option>
                      <option value="HI">HI</option>
                      <option value="IA">IA</option>
                      <option value="ID">ID</option>
                      <option value="IL">IL</option>
                      <option value="IN">IN</option>
                      <option value="KS">KS</option>
                      <option value="KY">KY</option>
                      <option value="LA">LA</option>
                      <option value="MA">MA</option>
                      <option value="MD">MD</option>
                      <option value="ME">ME</option>
                      <option value="MI">MI</option>
                      <option value="MN">MN</option>
                      <option value="MO">MO</option>
                      <option value="MS">MS</option>
                      <option value="MT">MT</option>
                      <option value="NC">NC</option>
                      <option value="NE">NE</option>
                      <option value="NH">NH</option>
                      <option value="NJ">NJ</option>
                      <option value="NM">NM</option>
                      <option value="NV">NV</option>
                      <option value="NY">NY</option>
                      <option value="ND">ND</option>
                      <option value="OH">OH</option>
                      <option value="OK">OK</option>
                      <option value="OR">OR</option>
                      <option value="PA">PA</option>
                      <option value="RI">RI</option>
                      <option value="SC">SC</option>
                      <option value="SD">SD</option>
                      <option value="TN">TN</option>
                      <option value="TX">TX</option>
                      <option value="UT">UT</option>
                      <option value="VT">VT</option>
                      <option value="VA">VA</option>
                      <option value="WA">WA</option>
                      <option value="WI">WI</option>
                      <option value="WV">WV</option>
                      <option value="WY">WY</option>
                    </select>
                    </div>
                  {/* <input
                    className="form-control"
                    type="text"
                    name="state"
                    placeholder=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.state}
                  /> */}
                  {touched.state && errors.state && (
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {errors.state}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>
                    ZIP <span>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="zip"
                    placeholder=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.zip}
                    maxLength={5}
                  />
                  {touched.zip && errors.zip && (
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {errors.zip}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>
                    Phone Number <span>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="phone_no"
                    placeholder=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone_no}
                    maxLength={10}
                  />
                  {touched.phone_no && errors.phone_no && (
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {errors.phone_no}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>
                    Best Time to Contact <span>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="contact_time"
                    placeholder=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.contact_time}
                  />
                  {touched.contact_time && errors.contact_time && (
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {errors.contact_time}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>
                    Email <span>*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="email"
                    placeholder=""
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {touched.email && errors.email && (
                    <span style={{ fontSize: "12px", color: "red" }}>
                      {errors.email}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label>
                    Type of HCP <span>*</span>
                  </label>
                  <div className="select">
                    <select className="form-control" name="hcp" onChange={handleChange} onBlur={handleBlur} value={values.hcp}>
                      <option value=""></option>
                      <option value="MD">MD</option>
                      <option value="DO">DO</option>
                      <option value="OD">OD</option>
                      {/*<option value="Ph.D">Ph.D</option>*/}
                      {/*<option value="R.Ph">R.Ph</option>*/}
                      {/*<option value="R.N.">R.N.</option>*/}
                      <option value="PharmD">PharmD</option>
                      {/*<option value="Other">Other</option>*/}
                    </select>
                    <div className="svgBG">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                      >
                        <path
                          d="M9.99974 1.00576C9.94329 1.18976 9.81531 1.31973 9.68631 1.45154C8.27012 2.89612 6.8547 4.34174 5.44106 5.7892C5.26761 5.96693 5.07041 6.04993 4.8331 5.96876C4.74446 5.93848 4.6571 5.87846 4.59043 5.81034C3.12008 4.31277 1.65203 2.81312 0.185008 1.31191C-0.0614971 1.05979 -0.0604746 0.733289 0.180922 0.478823C0.294084 0.35955 0.406735 0.238712 0.530371 0.131183C0.738815 -0.049944 1.02772 -0.0408085 1.23591 0.141885C1.26784 0.170072 1.29798 0.200607 1.32787 0.231143C2.52208 1.45127 3.71654 2.67114 4.91024 3.8918C4.93783 3.91998 4.96031 3.95313 5.02877 4.00611C5.04742 3.97088 5.05942 3.92886 5.08599 3.90171C6.29067 2.6688 7.49638 1.43692 8.70259 0.205567C8.92764 -0.024105 9.23775 -0.0700395 9.44823 0.118918C9.66281 0.311529 9.89859 0.492917 10 0.786009V1.0055L9.99974 1.00576Z"
                          fill="#80ABA5"
                        />
                      </svg>
                    </div>
                  </div>
                    {touched.hcp && errors.hcp && (
                      <span style={{ fontSize: "12px", color: "red" }}>
                        {errors.hcp}
                      </span>
                    )}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>
                    Preferred Method of Response <span>*</span>
                  </label>
                  <div className="select">
                    <select className="form-control" name="response" onChange={handleChange} onBlur={handleBlur} value={values.response}>
                      <option value="">Preferred Method of Response</option>
                      <option value="Phone">Phone</option>
                      <option value="Email">Email</option>
                      <option value="MSL Visit Requested">MSL Visit Requested</option>
                    </select>
                    <div className="svgBG">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="6"
                        viewBox="0 0 10 6"
                        fill="none"
                      >
                        <path
                          d="M9.99974 1.00576C9.94329 1.18976 9.81531 1.31973 9.68631 1.45154C8.27012 2.89612 6.8547 4.34174 5.44106 5.7892C5.26761 5.96693 5.07041 6.04993 4.8331 5.96876C4.74446 5.93848 4.6571 5.87846 4.59043 5.81034C3.12008 4.31277 1.65203 2.81312 0.185008 1.31191C-0.0614971 1.05979 -0.0604746 0.733289 0.180922 0.478823C0.294084 0.35955 0.406735 0.238712 0.530371 0.131183C0.738815 -0.049944 1.02772 -0.0408085 1.23591 0.141885C1.26784 0.170072 1.29798 0.200607 1.32787 0.231143C2.52208 1.45127 3.71654 2.67114 4.91024 3.8918C4.93783 3.91998 4.96031 3.95313 5.02877 4.00611C5.04742 3.97088 5.05942 3.92886 5.08599 3.90171C6.29067 2.6688 7.49638 1.43692 8.70259 0.205567C8.92764 -0.024105 9.23775 -0.0700395 9.44823 0.118918C9.66281 0.311529 9.89859 0.492917 10 0.786009V1.0055L9.99974 1.00576Z"
                          fill="#80ABA5"
                        />
                      </svg>
                    </div>
                  </div>
                    {touched.response && errors.response && (
                      <span style={{ fontSize: "12px", color: "red" }}>
                        {errors.response}
                      </span>
                    )}
                </div>
              </div>
            </div>

            <div className="enq">
              <div className="enq-header">
                <h2>Medical Inquiry</h2>
                <p>
                  Please type your question below with as much detail as
                  possible.
                </p>
              </div>
              <div className="enq-field">
                <textarea className="form-control" name="inquiry" onChange={handleChange} onBlur={handleBlur} value={values.inquiry}></textarea>
                {touched.inquiry && errors.inquiry && (
                  <span style={{ fontSize: "12px", color: "red" }}>
                    {errors.inquiry}
                  </span>
                )}
              </div>
              <div className="enq-Query">
                <p>
                 By clicking the acknowledgement, I hereby confirm that the medical information requested was at my initiation, as a healthcare provider, and not solicited in any manner by a Harrow representative or another party. I also certify that the information provided will not be shared with other parties. The wording above accurately reflects the medical information I am requesting to be provided to me by Harrow.
                </p>
              </div>
              <div className="checkbox">
                <div className="col_8">
                  <div className="approved">
                    <input id="check" className="form-control" type="checkbox" name="check" onChange={handleChange} onBlur={handleBlur} value={values.check} />
                    <label htmlFor="check">I have read the above disclaimer and agree to the terms.</label>
                  </div>
                    {touched.check && errors.check && (
                      <span style={{ fontSize: "12px", color: "red" }}>
                        {errors.check}
                      </span>
                    )}
                </div>
                <div className="col_4">
                  <div className="dateb">
                    <label>Date (dd/mm/yyyy)</label>
                    <input className="form-control" type="date" name="date" onChange={handleChange} onBlur={handleBlur} value={values.date} />
                    {touched.date && errors.date && (
                      <span style={{ fontSize: "12px", color: "red" }}>
                        {errors.date}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="submitbtn">
                <input className="form-control" type="submit" value={submitText} />
              </div>
            </div>
          </form>
        </div>
      </section>
      <Footer />
      {/* Bootstrap v4 Popup Modal */}
      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Form submittion status</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {formStatus === "success" ? <h6 className="text-success">Form submitted successfully</h6> : <h6 className="text-danger">Form submition failed</h6>}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-success" data-dismiss="modal">OK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicalInfoRequests;
