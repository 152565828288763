import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();
  useEffect(() => {
    $(".burgerIcon svg").click(function(){
      $(".header-menu").addClass("active");
      $(".headerR").addClass("active");
      $("body").addClass("active");
    });

  $(".headerR").click(function(){
      $(".header-menu").removeClass("active");
      $(this).removeClass("active");
      $("body").removeClass("active");
  });
  $(".header-menu ul li a").click(function(){
    $("body").removeClass("active");
  });

  $(".mobileIcon svg").click(function(){
      $(".header-menu").removeClass("active");
      $(".headerR").removeClass("active");
      $("body").removeClass("active");
  });

  $(window).scroll(function() {
    if ($(this).scrollTop() > 1){  
        $('header').addClass("sticky");
      }
      else{
        $('header').removeClass("sticky");
      }
    });
  }, []);
  return (
    <header>
      <div className="container">
        <nav>
          <div className="logo-brand">
            <NavLink to="/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="176"
                height="92"
                viewBox="0 0 176 92"
                fill="none"
              >
                <path
                  d="M64.5487 25.9394C66.9155 20.2394 69.2964 14.5534 71.6632 8.85338C72.6015 6.59858 73.5399 4.35779 74.4782 2.117C62.7701 6.57057 55.4455 16.7382 53.877 18.9649C54.8573 20.3514 58.0784 24.847 63.2182 29.1045C63.6524 28.0541 64.1006 26.9898 64.5347 25.9394"
                  fill="#676767"
                />
                <path
                  d="M86.1163 0.0162231C84.8698 -0.0257917 83.6514 0.0162231 82.475 0.114258C80.6824 4.42778 78.8617 8.7413 77.0691 13.0548C76.789 13.713 76.5229 14.3713 76.2428 15.0295C80.2762 12.4806 85.5281 11.4723 90.1357 12.9568C95.1215 14.5673 98.5807 18.5307 101.032 22.9283C102.516 25.5892 103.706 28.4182 104.785 31.2752C111.409 26.5976 115.597 20.8416 116.899 18.9509C114.616 15.6737 103.594 0.590425 86.1163 0.0162231Z"
                  fill="#676767"
                />
                <path
                  d="M96.0601 29.8608C95.0517 27.5639 93.9173 25.3092 92.4748 23.2644C92.2928 22.9983 92.0967 22.7603 91.9146 22.4942C92.6289 23.5025 91.9846 22.6062 91.7886 22.3541C91.4104 21.92 91.0183 21.4998 90.5982 21.1077C90.3881 20.9116 90.164 20.7155 89.9399 20.5195C89.8419 20.4354 89.7299 20.3654 89.6318 20.2674C89.7018 20.3234 89.7579 20.3794 89.7999 20.4214C89.996 20.5755 90.178 20.7575 89.7999 20.4214C89.7579 20.3794 89.7018 20.3514 89.6598 20.3234C89.5758 20.2674 89.4778 20.2114 89.3937 20.1553C89.5758 20.7435 89.6878 21.3738 89.6878 22.032C89.6878 25.4492 86.9429 28.2222 83.5537 28.2222C80.1645 28.2222 77.4195 25.4492 77.4195 22.032C76.9574 22.4661 76.5092 22.9143 76.0891 23.3905C75.865 23.6426 75.6549 23.8947 75.4448 24.1467C75.3748 24.2448 75.3048 24.3428 75.2348 24.4408C74.8286 25.001 74.4365 25.5892 74.0863 26.1915C73.4981 27.1578 72.994 28.1662 72.5318 29.2025C71.8596 30.8271 71.3274 32.5077 70.9072 34.2163C75.1507 36.205 80.0524 37.4934 85.5284 37.3114C90.094 37.1573 94.2114 36.0229 97.8527 34.3563C97.3065 32.8438 96.7183 31.3453 96.1021 29.8608"
                  fill="#676767"
                />
                <path
                  d="M5.02777 63.2486V73.01H0V52.5208H5.02777V61.498H13.2627V52.5208H18.2484V73.01H13.2627V63.2486H5.02777Z"
                  fill="#676767"
                />
                <path
                  d="M39.3681 52.1006L48.9755 73.01H43.8356L41.2727 67.422H33.3179L30.629 73.01H27.2118L37.1973 52.1006H39.3681ZM34.2703 65.5173H40.4325L37.3934 58.907L34.2703 65.5173Z"
                  fill="#676767"
                />
                <path
                  d="M58.0363 52.5208H65.739C67.0694 52.5208 68.2319 52.6609 69.2262 52.941C70.2206 53.2211 71.0468 53.5992 71.7051 54.1034C72.3633 54.6075 72.8535 55.1958 73.1896 55.882C73.5257 56.5682 73.6938 57.3105 73.6938 58.1228C73.6938 58.711 73.5817 59.2572 73.3577 59.7754C73.1336 60.2936 72.8115 60.7697 72.4053 61.2039C71.9992 61.638 71.509 62.0302 70.9208 62.3663C70.3466 62.7024 69.7024 62.9825 69.0161 63.2066L76.3127 73.01H70.8508L63.9744 63.7528H63.078V73.01H58.0503V52.5208H58.0363ZM63.064 61.9461H64.1564C64.7726 61.9461 65.3468 61.8621 65.865 61.694C66.3832 61.526 66.8454 61.2739 67.2235 60.9658C67.6016 60.6437 67.9097 60.2515 68.1198 59.7894C68.3299 59.3272 68.4419 58.795 68.4419 58.2208C68.4419 57.6746 68.3579 57.1845 68.1898 56.7223C68.0218 56.2741 67.7977 55.882 67.5036 55.5459C67.2095 55.2238 66.8594 54.9577 66.4392 54.7896C66.0331 54.6075 65.5849 54.5235 65.0947 54.5235H63.05V61.9601L63.064 61.9461Z"
                  fill="#676767"
                />
                <path
                  d="M84.394 52.5208H92.0968C93.4272 52.5208 94.5896 52.6609 95.584 52.941C96.5783 53.2211 97.4046 53.5992 98.0629 54.1034C98.7211 54.6075 99.2113 55.1958 99.5474 55.882C99.8835 56.5682 100.052 57.3105 100.052 58.1228C100.052 58.711 99.9395 59.2572 99.7154 59.7754C99.4914 60.2936 99.1692 60.7697 98.7631 61.2039C98.357 61.638 97.8668 62.0302 97.2786 62.3663C96.7044 62.7024 96.0602 62.9825 95.3739 63.2066L102.67 73.01H97.2086L90.3321 63.7528H89.4358V73.01H84.4081V52.5208H84.394ZM89.4218 61.9461H90.5142C91.1304 61.9461 91.7046 61.8621 92.2228 61.694C92.741 61.526 93.2031 61.2739 93.5813 60.9658C93.9594 60.6437 94.2675 60.2515 94.4776 59.7894C94.6877 59.3272 94.7997 58.795 94.7997 58.2208C94.7997 57.6746 94.7157 57.1845 94.5476 56.7223C94.3796 56.2741 94.1555 55.882 93.8614 55.5459C93.5673 55.2238 93.2172 54.9577 92.797 54.7896C92.3909 54.6075 91.9427 54.5235 91.4525 54.5235H89.4078V61.9601L89.4218 61.9461Z"
                  fill="#676767"
                />
                <path
                  d="M121.031 73.4581C119.378 73.4581 117.838 73.192 116.409 72.6598C114.981 72.1276 113.748 71.3714 112.698 70.419C111.647 69.4527 110.835 68.3183 110.233 66.9878C109.631 65.6574 109.337 64.2149 109.337 62.6323C109.337 61.0497 109.631 59.6632 110.233 58.3608C110.835 57.0583 111.661 55.9519 112.698 55.0276C113.748 54.1033 114.981 53.375 116.409 52.8709C117.838 52.3527 119.378 52.1006 121.031 52.1006C122.683 52.1006 124.224 52.3527 125.652 52.8709C127.081 53.389 128.313 54.1173 129.364 55.0416C130.414 55.9659 131.24 57.0723 131.842 58.3608C132.445 59.6492 132.753 61.0497 132.753 62.5763C132.753 63.6687 132.613 64.691 132.347 65.6574C132.081 66.6237 131.688 67.506 131.184 68.3043C130.68 69.1166 130.078 69.8308 129.378 70.4611C128.677 71.0913 127.879 71.6375 127.011 72.0716C126.142 72.5198 125.204 72.8559 124.196 73.094C123.187 73.3321 122.137 73.4441 121.045 73.4441M121.045 71.7215C122.039 71.7215 122.921 71.4974 123.692 71.0353C124.462 70.5731 125.092 69.9429 125.61 69.1446C126.128 68.3323 126.521 67.38 126.787 66.2736C127.053 65.1672 127.193 63.9488 127.193 62.6323C127.193 61.3158 127.053 60.1394 126.787 59.061C126.521 57.9827 126.128 57.0583 125.61 56.2881C125.092 55.5178 124.462 54.9156 123.692 54.4814C122.921 54.0473 122.053 53.8372 121.045 53.8372C120.036 53.8372 119.154 54.0473 118.384 54.4814C117.613 54.9156 116.983 55.5178 116.465 56.2881C115.947 57.0583 115.569 57.9967 115.303 59.061C115.037 60.1394 114.911 61.3298 114.911 62.6323C114.911 63.9348 115.037 65.1672 115.303 66.2736C115.569 67.38 115.947 68.3463 116.465 69.1446C116.983 69.9569 117.613 70.5871 118.384 71.0353C119.154 71.4974 120.036 71.7215 121.045 71.7215Z"
                  fill="#676767"
                />
                <path
                  d="M145.497 52.5206L150.693 65.7553L155.903 52.2966L161.155 65.6993L166.224 52.5206H169.586L161.533 73.304H159.25L155.048 62.6322L150.931 73.304H148.648L140.427 52.5206H145.497Z"
                  fill="#676767"
                />
                <path
                  d="M39.8582 83.0234C39.4521 82.2952 39.3961 82.1972 39.214 81.9171C39.5921 81.9451 39.7882 81.9591 40.0263 81.9591C40.2644 81.9591 40.4604 81.9591 40.8526 81.9171C41.0346 82.4072 41.987 84.3539 42.5192 85.2922C43.2614 83.9758 43.9337 82.6453 44.2418 81.9171C44.4378 81.9451 44.5499 81.9591 44.7179 81.9591C44.886 81.9591 44.998 81.9591 45.2081 81.9171C44.6059 82.7854 43.3174 85.0262 42.8553 85.9645C42.8553 86.2306 42.8553 86.3566 42.8553 86.5387C42.8553 87.6591 42.8833 88.6114 42.9533 89.1016C42.5752 89.0736 42.3931 89.0596 42.183 89.0596C41.973 89.0596 41.8049 89.0596 41.4548 89.1016C41.5248 88.5134 41.5668 87.421 41.5668 86.4547V86.0065C41.4268 85.7824 41.2307 85.4043 40.9366 84.9141C40.7825 84.62 40.6005 84.3119 40.4324 84.0318L39.8722 83.0234H39.8582Z"
                  fill="#676767"
                />
                <path
                  d="M50.2354 86.6507C50.2354 88.2613 49.2691 89.2276 47.6725 89.2276C46.0759 89.2276 45.0956 88.2613 45.0956 86.6087C45.0956 84.9561 46.0479 83.9898 47.6865 83.9898C49.3251 83.9898 50.2494 84.9421 50.2494 86.6507M46.412 86.6087C46.412 88.1073 46.8322 88.8495 47.6725 88.8495C48.5128 88.8495 48.9469 88.0933 48.9469 86.6367C48.9469 85.0962 48.5408 84.3679 47.6725 84.3679C46.8042 84.3679 46.412 85.1102 46.412 86.6087Z"
                  fill="#676767"
                />
                <path
                  d="M52.1402 84.1438C52.3503 84.1438 52.4763 84.1438 52.7424 84.1017C52.7004 84.928 52.6724 86.1044 52.6724 86.7067C52.6724 87.4769 52.7144 87.799 52.8405 88.0231C52.9945 88.3172 53.3166 88.4853 53.6948 88.4853C54.1709 88.4853 54.5491 88.1912 54.6891 87.687C54.7731 87.3509 54.7872 87.2529 54.7872 85.8384C54.7872 85.2782 54.7591 84.4799 54.7311 84.0877C55.0532 84.1157 55.1933 84.1298 55.4034 84.1298C55.5994 84.1298 55.7395 84.1298 56.0056 84.0877C55.9496 84.9981 55.9356 85.6423 55.9356 86.6786C55.9356 87.715 55.9636 88.4713 56.0056 89.1015C55.7535 89.0735 55.6275 89.0595 55.4314 89.0595C55.2633 89.0595 55.0953 89.0595 54.7872 89.1015C54.8012 88.8214 54.8152 88.7234 54.8292 88.2332C54.451 88.8774 53.8768 89.2275 53.1486 89.2275C52.6724 89.2275 52.2663 89.0735 51.9582 88.7934C51.622 88.4713 51.496 88.0931 51.496 87.3929C51.496 87.2388 51.496 86.9587 51.496 86.6786C51.496 86.2165 51.496 85.9504 51.496 85.5863C51.496 85.0121 51.496 84.6899 51.454 84.0737C51.7621 84.1017 51.9161 84.1157 52.1402 84.1157"
                  fill="#676767"
                />
                <path
                  d="M60.2355 85.2782C60.0395 85.1661 59.8854 85.1241 59.7033 85.1241C59.2832 85.1241 58.9331 85.3762 58.751 85.7683C58.667 86.0064 58.639 86.3005 58.639 87.0848C58.639 87.757 58.667 88.5833 58.709 89.1155C58.3589 89.0875 58.2188 89.0735 58.0788 89.0735C57.9107 89.0735 57.7847 89.0735 57.4205 89.1155C57.4906 88.5133 57.5046 87.8131 57.5046 86.6086C57.5046 85.6283 57.4906 85.1101 57.4205 84.1018C57.7426 84.1298 57.8827 84.1438 58.0508 84.1438C58.2188 84.1438 58.3449 84.1438 58.6109 84.1018C58.6109 84.3538 58.5829 84.802 58.5829 85.3202C58.9331 84.4939 59.4793 84.0037 60.0675 84.0037C60.1515 84.0037 60.2215 84.0037 60.3476 84.0457C60.2915 84.2978 60.2775 84.4519 60.2775 84.704C60.2775 84.872 60.2775 84.9841 60.3056 85.2222L60.2075 85.2922L60.2355 85.2782Z"
                  fill="#676767"
                />
                <path
                  d="M65.2775 89.2696C65.2775 90.39 65.3195 91.1463 65.3475 91.8746C65.0394 91.8325 64.8713 91.8185 64.6893 91.8185C64.5072 91.8185 64.3532 91.8185 64.045 91.8746C64.1011 91.1323 64.1431 89.3957 64.1431 88.3453C64.1431 86.3986 64.1151 84.8301 64.045 84.0878C64.3672 84.1018 64.5072 84.1158 64.6893 84.1158C64.8433 84.1158 64.9974 84.1158 65.2915 84.0878C65.2775 84.2979 65.2495 84.7741 65.2495 84.9281C65.5156 84.2979 66.0477 83.9758 66.79 83.9758C68.1065 83.9758 68.9468 84.9841 68.9468 86.5947C68.9468 88.2053 68.1065 89.1996 66.804 89.1996C66.0758 89.1996 65.5576 88.8775 65.2635 88.2613V89.2556L65.2775 89.2696ZM65.3195 86.6507C65.3195 87.9392 65.7396 88.6674 66.5099 88.6674C67.2802 88.6674 67.7003 87.9252 67.7003 86.5807C67.7003 85.2362 67.3082 84.578 66.5519 84.578C65.7256 84.578 65.3195 85.2642 65.3195 86.6507Z"
                  fill="#676767"
                />
                <path
                  d="M70.3751 85.3763C70.3751 85.3763 70.3751 85.2642 70.3751 85.2362C70.3751 85.0682 70.347 84.9421 70.263 84.648C70.8932 84.1718 71.4394 83.9758 72.1817 83.9758C72.8539 83.9758 73.4001 84.1998 73.6942 84.592C73.9183 84.9001 73.9883 85.2082 73.9883 85.9085C73.9883 85.9085 73.979 86.2026 73.9603 86.7908V87.1549C73.9603 87.9532 73.9603 88.2053 74.0023 88.3593C74.0584 88.5694 74.2124 88.7094 74.4365 88.7094C74.4925 88.7094 74.5345 88.7094 74.6326 88.6814V88.8775C74.3385 89.0596 74.0724 89.1296 73.7502 89.1296C73.2601 89.1296 72.994 88.9335 72.8259 88.4293C72.3778 88.9755 71.9296 89.2136 71.3414 89.2136C70.4591 89.2136 69.8849 88.6954 69.8849 87.8831C69.8849 87.3089 70.165 86.8748 70.6692 86.6227C70.7812 86.576 71.2527 86.45 72.0837 86.2446C72.6018 86.1185 72.7839 85.9505 72.7839 85.5723C72.7839 84.9561 72.3498 84.522 71.7195 84.522C71.1873 84.522 70.7392 84.8161 70.4871 85.3483H70.3611L70.3751 85.3763ZM71.7475 86.7628C71.3274 86.9308 71.1453 87.2389 71.1453 87.7431C71.1453 88.2893 71.4254 88.6114 71.8736 88.6114C72.3217 88.6114 72.6999 88.3173 72.7839 87.9112C72.8259 87.7011 72.8399 87.463 72.8399 86.7768C72.8399 86.6087 72.8399 86.5527 72.8399 86.4126C72.3357 86.5527 71.9716 86.6787 71.7475 86.7628Z"
                  fill="#676767"
                />
                <path
                  d="M76.9709 82.4913V82.6453C76.9429 82.8974 76.9289 83.1215 76.9009 83.3736C76.9009 83.3736 76.9009 83.7377 76.8729 84.1859L77.9653 84.1578C77.9373 84.3399 77.9373 84.3679 77.9373 84.4239C77.9373 84.48 77.9373 84.508 77.9653 84.676H77.8532H77.4611H77.0409H76.8869V84.8301C76.8869 85.0261 76.8869 85.0402 76.8589 86.4687V87.407C76.8589 88.0372 76.8869 88.2333 76.9709 88.4434C77.069 88.6674 77.265 88.7795 77.5591 88.7795C77.7412 88.7795 77.8812 88.7515 78.1193 88.6814L78.0633 89.0316C77.6292 89.1856 77.321 89.2416 76.9849 89.2416C76.1306 89.2416 75.6685 88.7375 75.6685 87.7991C75.6685 87.6871 75.6685 87.463 75.6685 87.2389C75.6965 86.1886 75.7105 85.3623 75.7105 84.648H75.5844C75.2483 84.648 75.0196 84.6574 74.8982 84.676C74.9262 84.564 74.9262 84.494 74.9262 84.3959C74.9262 84.3119 74.9262 84.2699 74.8982 84.1578L75.7245 84.1859V84.0318C75.7245 83.8917 75.7245 83.7937 75.6965 83.5836C75.6825 83.2055 75.6825 83.1495 75.6685 82.9254C76.0046 82.8414 76.4387 82.6593 76.9009 82.4072L76.9709 82.4773V82.4913Z"
                  fill="#676767"
                />
                <path
                  d="M80.3602 82.3372C80.3602 82.7154 80.0381 83.0375 79.6459 83.0375C79.2538 83.0375 78.9457 82.7154 78.9457 82.3372C78.9457 81.9311 79.2678 81.623 79.6599 81.623C80.0521 81.623 80.3602 81.9451 80.3602 82.3372ZM79.6879 84.1439C79.884 84.1439 80.0381 84.1439 80.2902 84.1019C80.2481 84.592 80.2061 85.9085 80.2061 86.6508C80.2061 87.5051 80.2341 88.5414 80.2902 89.1156C80.0241 89.0876 79.884 89.0736 79.6599 89.0736C79.4218 89.0736 79.2678 89.0736 78.9737 89.1156C79.0437 88.3454 79.0717 87.5331 79.0717 86.5107C79.0717 85.4884 79.0437 84.7881 78.9737 84.1019C79.3098 84.1299 79.4779 84.1439 79.6879 84.1439Z"
                  fill="#676767"
                />
                <path
                  d="M85.8501 88.1493L85.8221 88.2333C85.738 88.4434 85.71 88.5274 85.682 88.7095C85.0798 89.0876 84.6176 89.2276 83.9734 89.2276C82.4329 89.2276 81.4525 88.2193 81.4525 86.6227C81.4525 85.0262 82.3628 83.9898 83.8754 83.9898C85.2619 83.9898 86.0461 84.8161 86.0461 86.2726C86.0461 86.3286 86.0461 86.4687 86.0321 86.6087C85.556 86.5807 85.0938 86.5807 84.3655 86.5807C83.8614 86.5807 83.3292 86.5807 82.755 86.5947C82.755 86.6647 82.755 86.7348 82.755 86.7488C82.755 87.9392 83.3572 88.6675 84.3375 88.6675C84.8697 88.6675 85.3179 88.4714 85.752 88.0792L85.8641 88.1493H85.8501ZM84.0854 86.1466C84.5056 86.1466 84.5896 86.1466 84.8417 86.1326C84.8137 85.6144 84.7997 85.3903 84.7297 85.1662C84.5896 84.662 84.2955 84.3959 83.8614 84.3959C83.2171 84.3959 82.811 85.0402 82.769 86.1466C83.0351 86.1466 83.0911 86.1466 83.8193 86.1466H84.0994H84.0854Z"
                  fill="#676767"
                />
                <path
                  d="M87.8391 84.1439C88.0632 84.1439 88.1892 84.1439 88.4273 84.1018C88.3993 84.3819 88.3993 84.424 88.3853 84.9421C88.8334 84.2979 89.3516 83.9898 90.0659 83.9898C91.1022 83.9898 91.7325 84.634 91.7325 85.7124C91.7325 85.8945 91.7325 86.1886 91.7184 86.5247C91.7184 86.8748 91.6904 87.2109 91.6904 87.519C91.6904 88.1073 91.7185 88.6955 91.7465 89.1156C91.4524 89.0876 91.2843 89.0736 91.0742 89.0736C90.8641 89.0736 90.7381 89.0736 90.472 89.1156C90.5 88.6675 90.542 87.393 90.542 86.7908C90.542 85.9925 90.514 85.6284 90.416 85.3903C90.2619 84.9702 89.9398 84.7461 89.5197 84.7461C89.0575 84.7461 88.6934 85.0262 88.5533 85.4603C88.4553 85.7964 88.4413 86.0625 88.4413 87.351C88.4413 88.0372 88.4413 88.4294 88.4833 89.1156C88.1892 89.0876 88.0632 89.0736 87.8531 89.0736C87.657 89.0736 87.517 89.0736 87.2089 89.1156C87.2789 88.5414 87.3069 87.5891 87.3069 86.5107C87.3069 85.6704 87.2789 85.0402 87.2089 84.1018C87.503 84.1299 87.657 84.1439 87.8671 84.1439"
                  fill="#676767"
                />
                <path
                  d="M94.6731 82.4913V82.6453C94.6451 82.8974 94.6311 83.1215 94.603 83.3736C94.603 83.3736 94.603 83.7377 94.575 84.1859L95.6674 84.1578C95.6394 84.3399 95.6394 84.3679 95.6394 84.4239C95.6394 84.48 95.6394 84.508 95.6674 84.676H95.5554H95.1632H94.7431H94.589V84.8301C94.589 85.0261 94.589 85.0402 94.561 86.4687V87.407C94.561 88.0372 94.589 88.2333 94.6731 88.4434C94.7711 88.6674 94.9672 88.7795 95.2613 88.7795C95.4433 88.7795 95.5834 88.7515 95.8215 88.6814L95.7654 89.0316C95.3313 89.1856 95.0232 89.2416 94.6871 89.2416C93.8328 89.2416 93.3706 88.7375 93.3706 87.7991C93.3706 87.6871 93.3706 87.463 93.3706 87.2389C93.3986 86.1886 93.4126 85.3623 93.4126 84.648H93.2866C92.9505 84.648 92.7217 84.6574 92.6003 84.676C92.6284 84.564 92.6283 84.494 92.6283 84.3959C92.6283 84.3119 92.6284 84.2699 92.6003 84.1578L93.4266 84.1859V84.0318C93.4266 83.8917 93.4266 83.7937 93.3986 83.5836C93.3846 83.2055 93.3846 83.1495 93.3706 82.9254C93.7067 82.8414 94.1409 82.6593 94.603 82.4072L94.6731 82.4773V82.4913Z"
                  fill="#676767"
                />
                <path
                  d="M96.8862 87.8552C97.1242 88.4574 97.6144 88.8075 98.1606 88.8075C98.7068 88.8075 99.1129 88.4714 99.1129 88.0232C99.1129 87.6311 98.9169 87.449 98.1606 87.1829C97.5164 86.9448 97.2503 86.8048 97.0262 86.6227C96.7461 86.3846 96.5781 85.9925 96.5781 85.5724C96.5781 84.606 97.3203 83.9898 98.4967 83.9898C99.0989 83.9898 99.5611 84.1018 99.9813 84.3399L99.9392 84.466C99.8972 84.592 99.8552 84.7181 99.8132 84.8301C99.7852 84.8861 99.7432 85.0262 99.6731 85.2923H99.5471C99.393 84.7041 98.9869 84.3679 98.4127 84.3679C97.9225 84.3679 97.5724 84.662 97.5724 85.0962C97.5724 85.3623 97.6984 85.5724 97.9785 85.7264C98.2026 85.8525 98.3287 85.9085 98.9309 86.1045C99.8412 86.3987 100.191 86.7768 100.191 87.463C100.191 88.4994 99.281 89.2276 98.0066 89.2276C97.4884 89.2276 97.0682 89.1436 96.55 88.9195C96.6061 88.7655 96.7041 88.2753 96.7461 87.8552H96.8582H96.8862Z"
                  fill="#676767"
                />
                <path
                  d="M102.825 88.444C102.825 88.8645 102.489 89.2009 102.054 89.2009C101.62 89.2009 101.312 88.8645 101.312 88.444C101.312 88.0235 101.648 87.6871 102.068 87.6871C102.489 87.6871 102.825 88.0235 102.825 88.444Z"
                  fill="#676767"
                />
                <path
                  d="M113.524 82.8135C114.196 83.4297 114.533 84.326 114.533 85.5584C114.533 86.6508 114.196 87.4911 113.496 88.1634C112.768 88.8636 111.788 89.2137 110.597 89.2137C109.407 89.2137 108.384 88.8636 107.67 88.2054C106.984 87.5752 106.662 86.7489 106.662 85.6145C106.662 84.4801 106.998 83.5417 107.712 82.8835C108.454 82.1833 109.435 81.8331 110.597 81.8331C111.76 81.8331 112.838 82.1832 113.538 82.8415M108.566 85.5444C108.566 87.4491 109.295 88.5135 110.611 88.5135C111.928 88.5135 112.628 87.4631 112.628 85.5724C112.628 84.5081 112.474 83.8078 112.096 83.3036C111.76 82.8415 111.227 82.5754 110.597 82.5754C109.309 82.5754 108.566 83.6678 108.566 85.5444Z"
                  fill="#676767"
                />
                <path
                  d="M115.611 86.2586C115.611 85.6984 115.583 84.578 115.541 84.0458C115.919 84.0738 116.115 84.0879 116.367 84.0879C116.577 84.0879 116.717 84.0879 117.319 84.0458C117.235 85.1242 117.193 86.0766 117.193 86.7768C117.193 87.5891 117.207 87.8132 117.305 87.9672C117.417 88.1633 117.599 88.2613 117.852 88.2613C118.16 88.2613 118.384 88.1073 118.482 87.7852C118.538 87.6311 118.552 87.295 118.552 86.7488C118.552 86.6648 118.542 86.3286 118.524 85.7404C118.496 84.6901 118.496 84.6761 118.426 84.0458C118.762 84.0738 118.958 84.0879 119.308 84.0879C119.658 84.0879 119.854 84.0879 120.204 84.0458C120.176 84.508 120.134 85.6144 120.134 86.7208C120.134 87.463 120.162 88.5274 120.204 89.1156C119.896 89.0736 119.742 89.0596 119.42 89.0596C119.084 89.0596 118.874 89.0596 118.496 89.1156C118.51 88.9616 118.51 88.9196 118.524 88.5554C118.23 88.9896 117.796 89.1997 117.193 89.1997C116.703 89.1997 116.297 89.0596 116.017 88.7795C115.723 88.4994 115.583 88.0512 115.583 87.421C115.583 87.253 115.583 87.0849 115.583 86.8468V86.2446L115.611 86.2586Z"
                  fill="#676767"
                />
                <path
                  d="M124.63 85.6563C124.434 85.4882 124.238 85.4042 123.986 85.4042C123.65 85.4042 123.383 85.6143 123.243 85.9924C123.173 86.1885 123.145 86.4686 123.145 86.9587C123.145 87.4909 123.187 88.4713 123.257 89.1015C122.851 89.0595 122.641 89.0455 122.375 89.0455C122.137 89.0455 121.913 89.0455 121.479 89.1015C121.521 88.3592 121.563 87.1128 121.563 86.5666C121.563 85.9644 121.521 84.5219 121.479 84.0317C121.829 84.0597 122.039 84.0737 122.305 84.0737C122.571 84.0737 122.795 84.0737 123.173 84.0317C123.145 84.3398 123.145 84.5359 123.145 84.816V85.0541H123.215C123.509 84.3118 123.86 84.0177 124.434 84.0177C124.574 84.0177 124.672 84.0177 124.882 84.0737C124.798 84.3258 124.742 84.788 124.742 85.2221C124.742 85.3342 124.742 85.3902 124.77 85.5442L124.644 85.6423L124.63 85.6563Z"
                  fill="#676767"
                />
                <path
                  d="M130.009 91.3767C129.555 91.3099 129.296 91.2931 128.955 91.2931C128.615 91.2931 128.388 91.3099 127.951 91.3767C127.983 90.7751 128.064 88.1852 128.064 87.2327C128.064 86.9877 128.059 86.1578 128.048 84.743C127.999 82.4037 127.999 82.3703 127.967 81.9191C128.372 81.9692 128.599 81.9859 128.907 81.9859C129.215 81.9859 129.441 81.9859 129.944 81.9191C129.879 82.3703 129.879 82.4371 129.863 82.5875C130.203 82.0695 130.738 81.8188 131.435 81.8188C133.006 81.8188 134.044 83.1055 134.044 85.0438C134.044 86.8818 133.039 88.0849 131.516 88.0849C130.819 88.0849 130.349 87.8677 129.928 87.3497V88.3356C129.928 89.5052 129.928 89.689 130.009 91.1762V91.3934V91.3767ZM129.83 84.8934C129.83 86.1967 130.268 87.0489 130.965 87.0489C131.662 87.0489 132.083 86.2636 132.083 84.9435C132.083 83.5566 131.678 82.7546 130.981 82.7546C130.252 82.7546 129.814 83.5566 129.814 84.8934"
                  fill="#676767"
                />
                <path
                  d="M134.531 86.2586C134.531 85.6984 134.503 84.578 134.461 84.0458C134.839 84.0738 135.035 84.0879 135.287 84.0879C135.498 84.0879 135.638 84.0879 136.24 84.0458C136.156 85.1242 136.114 86.0766 136.114 86.7768C136.114 87.5891 136.128 87.8132 136.226 87.9672C136.338 88.1633 136.52 88.2613 136.772 88.2613C137.08 88.2613 137.304 88.1073 137.402 87.7852C137.458 87.6311 137.472 87.295 137.472 86.7488C137.472 86.6648 137.463 86.3286 137.444 85.7404C137.416 84.6901 137.416 84.6761 137.346 84.0458C137.682 84.0738 137.878 84.0879 138.229 84.0879C138.579 84.0879 138.775 84.0879 139.125 84.0458C139.097 84.508 139.055 85.6144 139.055 86.7208C139.055 87.463 139.083 88.5274 139.125 89.1156C138.817 89.0736 138.649 89.0596 138.341 89.0596C138.004 89.0596 137.794 89.0596 137.416 89.1156C137.444 88.9616 137.444 88.9196 137.444 88.5554C137.15 88.9896 136.716 89.1997 136.114 89.1997C135.624 89.1997 135.217 89.0596 134.923 88.7795C134.629 88.4994 134.489 88.0512 134.489 87.421C134.489 87.253 134.489 87.0849 134.489 86.8468V86.2446L134.531 86.2586Z"
                  fill="#676767"
                />
                <path
                  d="M143.551 85.6563C143.355 85.4882 143.159 85.4042 142.907 85.4042C142.571 85.4042 142.304 85.6143 142.164 85.9924C142.094 86.1885 142.066 86.4686 142.066 86.9587C142.066 87.4909 142.108 88.4713 142.178 89.1015C141.772 89.0595 141.562 89.0455 141.296 89.0455C141.058 89.0455 140.834 89.0455 140.4 89.1015C140.442 88.3592 140.484 87.1128 140.484 86.5666C140.484 85.9644 140.442 84.5219 140.4 84.0317C140.75 84.0597 140.96 84.0737 141.226 84.0737C141.492 84.0737 141.716 84.0737 142.094 84.0317C142.066 84.3398 142.066 84.5359 142.066 84.816V85.0541H142.136C142.43 84.3118 142.781 84.0177 143.355 84.0177C143.495 84.0177 143.593 84.0177 143.803 84.0737C143.719 84.3258 143.663 84.788 143.663 85.2221C143.663 85.3342 143.663 85.3902 143.691 85.5442L143.565 85.6423L143.551 85.6563Z"
                  fill="#676767"
                />
                <path
                  d="M146.24 91.9726C145.847 91.9166 145.623 91.9026 145.329 91.9026C145.035 91.9026 144.839 91.9166 144.461 91.9726C144.489 91.4684 144.559 89.2977 144.559 88.4994C144.559 88.294 144.554 87.5984 144.545 86.4127C144.503 84.452 144.503 84.424 144.475 84.0458C144.825 84.0878 145.021 84.1018 145.287 84.1018C145.553 84.1018 145.749 84.1018 146.184 84.0458C146.128 84.424 146.128 84.48 146.114 84.606C146.408 84.1719 146.87 83.9618 147.472 83.9618C148.83 83.9618 149.727 85.0402 149.727 86.6647C149.727 88.2053 148.858 89.2136 147.542 89.2136C146.94 89.2136 146.534 89.0316 146.17 88.5974V89.4237C146.17 90.4041 146.17 90.5581 146.24 91.8046V91.9866V91.9726ZM146.086 86.5387C146.086 87.6311 146.464 88.3453 147.066 88.3453C147.668 88.3453 148.032 87.6871 148.032 86.5807C148.032 85.4183 147.682 84.7461 147.08 84.7461C146.45 84.7461 146.072 85.4183 146.072 86.5387"
                  fill="#676767"
                />
                <path
                  d="M155.007 84.55C155.539 85.0261 155.833 85.7404 155.833 86.5807C155.833 87.421 155.581 88.0372 155.063 88.5134C154.573 88.9895 153.97 89.1996 153.116 89.1996C151.422 89.1996 150.413 88.2053 150.413 86.5527C150.413 85.7264 150.735 84.9841 151.282 84.522C151.772 84.1298 152.346 83.9478 153.13 83.9478C153.914 83.9478 154.517 84.1438 155.007 84.55ZM152.094 86.5527C152.094 87.9532 152.43 88.6254 153.13 88.6254C153.83 88.6254 154.153 87.9392 154.153 86.5947C154.153 85.2502 153.816 84.522 153.13 84.522C152.444 84.522 152.094 85.2082 152.094 86.5527Z"
                  fill="#676767"
                />
                <path
                  d="M156.926 87.491C157.122 88.0932 157.57 88.4433 158.172 88.4433C158.648 88.4433 159.013 88.2052 159.013 87.8831C159.013 87.617 158.859 87.505 158.172 87.2529C157.514 87.0008 157.346 86.9168 157.08 86.7347C156.716 86.4546 156.548 86.1045 156.548 85.6283C156.548 84.5779 157.332 83.9337 158.606 83.9337C159.251 83.9337 159.839 84.0598 160.259 84.3118C160.119 84.746 160.063 84.9981 159.979 85.4603L159.783 85.4883C159.629 84.9701 159.139 84.606 158.578 84.606C158.214 84.606 157.976 84.788 157.976 85.0681C157.976 85.3902 158.172 85.5303 158.971 85.8104C159.573 86.0205 159.895 86.1745 160.119 86.3706C160.399 86.6227 160.567 87.0428 160.567 87.449C160.567 88.4853 159.615 89.1996 158.242 89.1996C157.556 89.1996 157.038 89.0735 156.562 88.7934C156.66 88.4853 156.73 87.9811 156.73 87.631V87.491H156.926Z"
                  fill="#676767"
                />
                <path
                  d="M165.888 87.7711C165.734 88.2193 165.72 88.2753 165.608 88.7374C165.048 89.0596 164.516 89.1996 163.872 89.1996C162.261 89.1996 161.225 88.1492 161.225 86.5527C161.225 85.7544 161.463 85.0822 161.939 84.606C162.373 84.1578 162.961 83.9478 163.745 83.9478C165.146 83.9478 166 84.774 166 86.1045C166 86.3006 166 86.4126 165.972 86.7627C165.188 86.7487 164.754 86.7347 164.278 86.7347C163.802 86.7347 163.605 86.7347 162.919 86.7627C162.919 86.8048 162.919 86.8608 162.919 86.8748C162.919 87.7851 163.479 88.3733 164.348 88.3733C164.894 88.3733 165.286 88.1772 165.748 87.6871L165.916 87.7851L165.888 87.7711ZM163.507 86.1325C163.745 86.1325 163.9 86.1325 164.474 86.1185C164.46 85.6003 164.432 85.4323 164.376 85.2222C164.25 84.8161 163.998 84.592 163.675 84.592C163.143 84.592 162.891 85.0822 162.877 86.1185C163.129 86.1325 163.255 86.1325 163.507 86.1325Z"
                  fill="#676767"
                />
                <path
                  d="M168.648 88.2472C168.648 88.7514 168.256 89.1436 167.737 89.1436C167.275 89.1436 166.869 88.7374 166.869 88.2472C166.869 87.7571 167.275 87.3649 167.765 87.3649C168.256 87.3649 168.648 87.7571 168.648 88.2472Z"
                  fill="#676767"
                />
                <path
                  d="M176 54.0332C176 55.2517 175.006 56.26 173.787 56.26C172.569 56.26 171.574 55.2657 171.574 54.0332C171.574 52.8008 172.569 51.8065 173.815 51.8065C175.006 51.8065 175.986 52.8148 175.986 54.0332M171.925 54.0332C171.925 55.0836 172.765 55.9379 173.787 55.9379C174.81 55.9379 175.636 55.0836 175.636 54.0332C175.636 52.9829 174.81 52.1146 173.815 52.1146C172.751 52.1146 171.925 52.9549 171.925 54.0332ZM175.006 55.3217H174.922C174.81 55.3217 174.698 55.3077 174.614 55.3077C174.487 55.3077 174.375 55.3077 174.235 55.3217L174.207 55.2797V55.2517C173.941 54.7615 173.857 54.6074 173.563 54.2013C173.563 54.5094 173.563 54.5654 173.591 54.8875C173.591 55.0696 173.591 55.1676 173.591 55.3217C173.465 55.3077 173.367 55.2937 173.255 55.2937C173.185 55.2937 173.101 55.2937 172.905 55.3077H172.863V55.2657C172.863 55.0696 172.891 54.4114 172.891 54.1453C172.891 53.7111 172.891 53.6691 172.877 53.1929V52.8148V52.7308C173.073 52.7448 173.101 52.7448 173.227 52.7448C173.269 52.7448 173.381 52.7448 173.549 52.7448C173.675 52.7448 173.787 52.7448 173.885 52.7448C174.473 52.7448 174.768 52.9549 174.768 53.417C174.768 53.7531 174.572 53.9912 174.221 54.0893C174.417 54.4254 174.754 54.9156 175.048 55.2797L175.006 55.3357V55.3217ZM173.563 53.9212C173.563 53.9212 173.647 53.9212 173.689 53.9212C173.969 53.9212 174.095 53.7812 174.095 53.473C174.095 53.1789 173.983 53.0529 173.731 53.0529C173.675 53.0529 173.647 53.0529 173.577 53.0529V53.249V53.445V53.8932L173.563 53.9212Z"
                  fill="#676767"
                />
                <path
                  d="M64.5487 25.9394C66.9155 20.2394 69.2964 14.5534 71.6632 8.85338C72.6015 6.59858 73.5399 4.35779 74.4782 2.117C62.7701 6.57057 55.4455 16.7382 53.877 18.9649C54.8573 20.3514 58.0784 24.847 63.2182 29.1045C63.6524 28.0541 64.1006 26.9898 64.5347 25.9394"
                  fill="#31708A"
                />
                <path
                  d="M85.6704 0.0162231C84.4239 -0.0257917 83.2055 0.0162231 82.0291 0.114258C80.2364 4.42778 78.4158 8.7413 76.6232 13.0548C76.3431 13.713 76.077 14.3713 75.7969 15.0295C79.8303 12.4806 85.0821 11.4723 89.6898 12.9568C94.6755 14.5673 98.1347 18.5307 100.586 22.9283C102.07 25.5892 103.261 28.4182 104.339 31.2752C110.963 26.5976 115.151 20.8416 116.453 18.9509C114.17 15.6737 103.149 0.590425 85.6704 0.0162231Z"
                  fill="#31708A"
                />
                <path
                  d="M96.0601 29.8608C95.0517 27.564 93.9173 25.3092 92.4748 23.2645C92.2928 22.9984 92.0967 22.7603 91.9146 22.4942C92.6289 23.5026 91.9846 22.6063 91.7886 22.3542C91.4104 21.92 91.0183 21.4999 90.5982 21.1077C90.3881 20.9117 90.164 20.7156 89.9399 20.5195C89.8419 20.4355 89.7299 20.3655 89.6318 20.2674C89.7018 20.3235 89.7579 20.3795 89.7999 20.4215C89.996 20.5755 90.178 20.7576 89.7999 20.4215C89.7579 20.3795 89.7018 20.3515 89.6598 20.3235C89.5758 20.2674 89.4778 20.2114 89.3937 20.1554C89.5758 20.7436 89.6878 21.3738 89.6878 22.0321C89.6878 25.4493 86.9429 28.2222 83.5537 28.2222C80.1645 28.2222 77.4195 25.4493 77.4195 22.0321C76.9574 22.4662 76.5092 22.9144 76.0891 23.3905C75.865 23.6426 75.6549 23.8947 75.4448 24.1468C75.3748 24.2448 75.3048 24.3429 75.2348 24.4409C74.8286 25.0011 74.4365 25.5893 74.0863 26.1915C73.4981 27.1579 72.994 28.1662 72.5318 29.2026C71.8596 30.8272 71.3274 32.5078 70.9072 34.2164C75.1507 36.2051 80.0524 37.4935 85.5284 37.3114C90.094 37.1574 94.2114 36.023 97.8527 34.3564C97.3065 32.8439 96.7183 31.3453 96.1021 29.8608"
                  fill="#7BA09B"
                />
                <path
                  d="M5.02777 63.2486V73.01H0V52.5208H5.02777V61.498H13.2627V52.5208H18.2484V73.01H13.2627V63.2486H5.02777Z"
                  fill="#1A2549"
                />
                <path
                  d="M39.3681 52.1006L48.9755 73.01H43.8356L41.2727 67.422H33.3179L30.629 73.01H27.2118L37.1973 52.1006H39.3681ZM34.2703 65.5173H40.4325L37.3934 58.907L34.2703 65.5173Z"
                  fill="#1A2549"
                />
                <path
                  d="M58.0363 52.5208H65.739C67.0694 52.5208 68.2319 52.6609 69.2262 52.941C70.2206 53.2211 71.0468 53.5992 71.7051 54.1034C72.3633 54.6075 72.8535 55.1958 73.1896 55.882C73.5257 56.5682 73.6938 57.3105 73.6938 58.1228C73.6938 58.711 73.5817 59.2572 73.3577 59.7754C73.1336 60.2936 72.8115 60.7697 72.4053 61.2039C71.9992 61.638 71.509 62.0302 70.9208 62.3663C70.3466 62.7024 69.7024 62.9825 69.0161 63.2066L76.3127 73.01H70.8508L63.9744 63.7528H63.078V73.01H58.0503V52.5208H58.0363ZM63.064 61.9461H64.1564C64.7726 61.9461 65.3468 61.8621 65.865 61.694C66.3832 61.526 66.8454 61.2739 67.2235 60.9658C67.6016 60.6437 67.9097 60.2515 68.1198 59.7894C68.3299 59.3272 68.4419 58.795 68.4419 58.2208C68.4419 57.6746 68.3579 57.1845 68.1898 56.7223C68.0218 56.2741 67.7977 55.882 67.5036 55.5459C67.2095 55.2238 66.8594 54.9577 66.4392 54.7896C66.0331 54.6075 65.5849 54.5235 65.0947 54.5235H63.05V61.9601L63.064 61.9461Z"
                  fill="#1A2549"
                />
                <path
                  d="M84.394 52.5208H92.0968C93.4272 52.5208 94.5896 52.6609 95.584 52.941C96.5783 53.2211 97.4046 53.5992 98.0629 54.1034C98.7211 54.6075 99.2113 55.1958 99.5474 55.882C99.8835 56.5682 100.052 57.3105 100.052 58.1228C100.052 58.711 99.9395 59.2572 99.7154 59.7754C99.4914 60.2936 99.1692 60.7697 98.7631 61.2039C98.357 61.638 97.8668 62.0302 97.2786 62.3663C96.7044 62.7024 96.0602 62.9825 95.3739 63.2066L102.67 73.01H97.2086L90.3321 63.7528H89.4358V73.01H84.4081V52.5208H84.394ZM89.4218 61.9461H90.5142C91.1304 61.9461 91.7046 61.8621 92.2228 61.694C92.741 61.526 93.2031 61.2739 93.5813 60.9658C93.9594 60.6437 94.2675 60.2515 94.4776 59.7894C94.6877 59.3272 94.7997 58.795 94.7997 58.2208C94.7997 57.6746 94.7157 57.1845 94.5476 56.7223C94.3796 56.2741 94.1555 55.882 93.8614 55.5459C93.5673 55.2238 93.2172 54.9577 92.797 54.7896C92.3909 54.6075 91.9427 54.5235 91.4525 54.5235H89.4078V61.9601L89.4218 61.9461Z"
                  fill="#1A2549"
                />
                <path
                  d="M121.031 73.4581C119.378 73.4581 117.838 73.192 116.409 72.6598C114.981 72.1276 113.748 71.3714 112.698 70.419C111.647 69.4527 110.835 68.3183 110.233 66.9878C109.631 65.6574 109.337 64.2149 109.337 62.6323C109.337 61.0497 109.631 59.6632 110.233 58.3608C110.835 57.0583 111.661 55.9519 112.698 55.0276C113.748 54.1033 114.981 53.375 116.409 52.8709C117.838 52.3527 119.378 52.1006 121.031 52.1006C122.683 52.1006 124.224 52.3527 125.652 52.8709C127.081 53.389 128.313 54.1173 129.364 55.0416C130.414 55.9659 131.24 57.0723 131.842 58.3608C132.445 59.6492 132.753 61.0497 132.753 62.5763C132.753 63.6687 132.613 64.691 132.347 65.6574C132.081 66.6237 131.688 67.506 131.184 68.3043C130.68 69.1166 130.078 69.8308 129.378 70.4611C128.677 71.0913 127.879 71.6375 127.011 72.0716C126.142 72.5198 125.204 72.8559 124.196 73.094C123.187 73.3321 122.137 73.4441 121.045 73.4441M121.045 71.7215C122.039 71.7215 122.921 71.4974 123.692 71.0353C124.462 70.5731 125.092 69.9429 125.61 69.1446C126.128 68.3323 126.521 67.38 126.787 66.2736C127.053 65.1672 127.193 63.9488 127.193 62.6323C127.193 61.3158 127.053 60.1394 126.787 59.061C126.521 57.9827 126.128 57.0583 125.61 56.2881C125.092 55.5178 124.462 54.9156 123.692 54.4814C122.921 54.0473 122.053 53.8372 121.045 53.8372C120.036 53.8372 119.154 54.0473 118.384 54.4814C117.613 54.9156 116.983 55.5178 116.465 56.2881C115.947 57.0583 115.569 57.9967 115.303 59.061C115.037 60.1394 114.911 61.3298 114.911 62.6323C114.911 63.9348 115.037 65.1672 115.303 66.2736C115.569 67.38 115.947 68.3463 116.465 69.1446C116.983 69.9569 117.613 70.5871 118.384 71.0353C119.154 71.4974 120.036 71.7215 121.045 71.7215Z"
                  fill="#1A2549"
                />
                <path
                  d="M145.497 52.5206L150.693 65.7553L155.903 52.2966L161.155 65.6993L166.224 52.5206H169.586L161.533 73.304H159.25L155.048 62.6322L150.931 73.304H148.648L140.427 52.5206H145.497Z"
                  fill="#1A2549"
                />
                <path
                  d="M39.8582 83.0235C39.4521 82.2952 39.3961 82.1972 39.214 81.9171C39.5921 81.9451 39.7882 81.9591 40.0263 81.9591C40.2644 81.9591 40.4604 81.9591 40.8526 81.9171C41.0346 82.4073 41.987 84.354 42.5192 85.2923C43.2614 83.9758 43.9337 82.6454 44.2418 81.9171C44.4378 81.9451 44.5499 81.9591 44.7179 81.9591C44.886 81.9591 44.998 81.9591 45.2081 81.9171C44.6059 82.7854 43.3174 85.0262 42.8553 85.9645C42.8553 86.2306 42.8553 86.3567 42.8553 86.5387C42.8553 87.6591 42.8833 88.6115 42.9533 89.1016C42.5752 89.0736 42.3931 89.0596 42.183 89.0596C41.973 89.0596 41.8049 89.0596 41.4548 89.1016C41.5248 88.5134 41.5668 87.4211 41.5668 86.4547V86.0066C41.4268 85.7825 41.2307 85.4043 40.9366 84.9142C40.7825 84.6201 40.6005 84.312 40.4324 84.0319L39.8722 83.0235H39.8582Z"
                  fill="#31708A"
                />
                <path
                  d="M50.2354 86.6507C50.2354 88.2613 49.2691 89.2276 47.6725 89.2276C46.0759 89.2276 45.0956 88.2613 45.0956 86.6087C45.0956 84.9561 46.0479 83.9898 47.6865 83.9898C49.3251 83.9898 50.2494 84.9421 50.2494 86.6507M46.412 86.6087C46.412 88.1073 46.8322 88.8495 47.6725 88.8495C48.5128 88.8495 48.9469 88.0933 48.9469 86.6367C48.9469 85.0962 48.5408 84.3679 47.6725 84.3679C46.8042 84.3679 46.412 85.1102 46.412 86.6087Z"
                  fill="#31708A"
                />
                <path
                  d="M52.1402 84.1438C52.3503 84.1438 52.4763 84.1438 52.7424 84.1018C52.7004 84.9281 52.6724 86.1045 52.6724 86.7067C52.6724 87.477 52.7144 87.7991 52.8405 88.0232C52.9945 88.3173 53.3166 88.4853 53.6948 88.4853C54.1709 88.4853 54.5491 88.1912 54.6891 87.6871C54.7731 87.3509 54.7872 87.2529 54.7872 85.8384C54.7872 85.2782 54.7591 84.4799 54.7311 84.0878C55.0532 84.1158 55.1933 84.1298 55.4034 84.1298C55.5994 84.1298 55.7395 84.1298 56.0056 84.0878C55.9496 84.9981 55.9356 85.6423 55.9356 86.6787C55.9356 87.7151 55.9636 88.4713 56.0056 89.1016C55.7535 89.0736 55.6275 89.0596 55.4314 89.0596C55.2633 89.0596 55.0953 89.0595 54.7872 89.1016C54.8012 88.8215 54.8152 88.7234 54.8292 88.2333C54.451 88.8775 53.8768 89.2276 53.1486 89.2276C52.6724 89.2276 52.2663 89.0736 51.9582 88.7935C51.622 88.4713 51.496 88.0932 51.496 87.393C51.496 87.2389 51.496 86.9588 51.496 86.6787C51.496 86.2165 51.496 85.9505 51.496 85.5863C51.496 85.0121 51.496 84.69 51.454 84.0738C51.7621 84.1018 51.9161 84.1158 52.1402 84.1158"
                  fill="#31708A"
                />
                <path
                  d="M60.2355 85.2782C60.0395 85.1661 59.8854 85.1241 59.7033 85.1241C59.2832 85.1241 58.9331 85.3762 58.751 85.7683C58.667 86.0064 58.639 86.3005 58.639 87.0848C58.639 87.757 58.667 88.5833 58.709 89.1155C58.3589 89.0875 58.2188 89.0735 58.0788 89.0735C57.9107 89.0735 57.7847 89.0735 57.4205 89.1155C57.4906 88.5133 57.5046 87.8131 57.5046 86.6086C57.5046 85.6283 57.4906 85.1101 57.4205 84.1018C57.7426 84.1298 57.8827 84.1438 58.0508 84.1438C58.2188 84.1438 58.3449 84.1438 58.6109 84.1018C58.6109 84.3538 58.5829 84.802 58.5829 85.3202C58.9331 84.4939 59.4793 84.0037 60.0675 84.0037C60.1515 84.0037 60.2215 84.0037 60.3476 84.0457C60.2915 84.2978 60.2775 84.4519 60.2775 84.704C60.2775 84.872 60.2775 84.9841 60.3056 85.2222L60.2075 85.2922L60.2355 85.2782Z"
                  fill="#31708A"
                />
                <path
                  d="M65.2775 89.2696C65.2775 90.39 65.3195 91.1463 65.3475 91.8746C65.0394 91.8325 64.8713 91.8185 64.6893 91.8185C64.5072 91.8185 64.3532 91.8185 64.045 91.8746C64.1011 91.1323 64.1431 89.3957 64.1431 88.3453C64.1431 86.3986 64.1151 84.8301 64.045 84.0878C64.3672 84.1018 64.5072 84.1158 64.6893 84.1158C64.8433 84.1158 64.9974 84.1158 65.2915 84.0878C65.2775 84.2979 65.2495 84.7741 65.2495 84.9281C65.5156 84.2979 66.0477 83.9758 66.79 83.9758C68.1065 83.9758 68.9468 84.9841 68.9468 86.5947C68.9468 88.2053 68.1065 89.1996 66.804 89.1996C66.0758 89.1996 65.5576 88.8775 65.2635 88.2613V89.2556L65.2775 89.2696ZM65.3195 86.6507C65.3195 87.9392 65.7396 88.6674 66.5099 88.6674C67.2802 88.6674 67.7003 87.9252 67.7003 86.5807C67.7003 85.2362 67.3082 84.578 66.5519 84.578C65.7256 84.578 65.3195 85.2642 65.3195 86.6507Z"
                  fill="#31708A"
                />
                <path
                  d="M70.3751 85.3763C70.3751 85.3763 70.3751 85.2642 70.3751 85.2362C70.3751 85.0682 70.347 84.9421 70.263 84.648C70.8932 84.1718 71.4394 83.9758 72.1817 83.9758C72.8539 83.9758 73.4001 84.1998 73.6942 84.592C73.9183 84.9001 73.9883 85.2082 73.9883 85.9085C73.9883 85.9085 73.979 86.2026 73.9603 86.7908V87.1549C73.9603 87.9532 73.9603 88.2053 74.0023 88.3593C74.0584 88.5694 74.2124 88.7094 74.4365 88.7094C74.4925 88.7094 74.5345 88.7094 74.6326 88.6814V88.8775C74.3385 89.0596 74.0724 89.1296 73.7502 89.1296C73.2601 89.1296 72.994 88.9335 72.8259 88.4293C72.3778 88.9755 71.9296 89.2136 71.3414 89.2136C70.4591 89.2136 69.8849 88.6954 69.8849 87.8831C69.8849 87.3089 70.165 86.8748 70.6692 86.6227C70.7812 86.576 71.2527 86.45 72.0837 86.2446C72.6018 86.1185 72.7839 85.9505 72.7839 85.5723C72.7839 84.9561 72.3498 84.522 71.7195 84.522C71.1873 84.522 70.7392 84.8161 70.4871 85.3483H70.3611L70.3751 85.3763ZM71.7475 86.7628C71.3274 86.9308 71.1453 87.2389 71.1453 87.7431C71.1453 88.2893 71.4254 88.6114 71.8736 88.6114C72.3217 88.6114 72.6999 88.3173 72.7839 87.9112C72.8259 87.7011 72.8399 87.463 72.8399 86.7768C72.8399 86.6087 72.8399 86.5527 72.8399 86.4126C72.3357 86.5527 71.9716 86.6787 71.7475 86.7628Z"
                  fill="#31708A"
                />
                <path
                  d="M76.9709 82.4913V82.6453C76.9429 82.8974 76.9289 83.1215 76.9009 83.3736C76.9009 83.3736 76.9009 83.7377 76.8729 84.1859L77.9653 84.1578C77.9373 84.3399 77.9373 84.3679 77.9373 84.4239C77.9373 84.48 77.9373 84.508 77.9653 84.676H77.8532H77.4611H77.0409H76.8869V84.8301C76.8869 85.0261 76.8869 85.0402 76.8589 86.4687V87.407C76.8589 88.0372 76.8869 88.2333 76.9709 88.4434C77.069 88.6674 77.265 88.7795 77.5591 88.7795C77.7412 88.7795 77.8812 88.7515 78.1193 88.6814L78.0633 89.0316C77.6292 89.1856 77.321 89.2416 76.9849 89.2416C76.1306 89.2416 75.6685 88.7375 75.6685 87.7991C75.6685 87.6871 75.6685 87.463 75.6685 87.2389C75.6965 86.1886 75.7105 85.3623 75.7105 84.648H75.5844C75.2483 84.648 75.0196 84.6574 74.8982 84.676C74.9262 84.564 74.9262 84.494 74.9262 84.3959C74.9262 84.3119 74.9262 84.2699 74.8982 84.1578L75.7245 84.1859V84.0318C75.7245 83.8917 75.7245 83.7937 75.6965 83.5836C75.6825 83.2055 75.6825 83.1495 75.6685 82.9254C76.0046 82.8414 76.4387 82.6593 76.9009 82.4072L76.9709 82.4773V82.4913Z"
                  fill="#31708A"
                />
                <path
                  d="M80.3602 82.3372C80.3602 82.7154 80.0381 83.0375 79.6459 83.0375C79.2538 83.0375 78.9457 82.7154 78.9457 82.3372C78.9457 81.9311 79.2678 81.623 79.6599 81.623C80.0521 81.623 80.3602 81.9451 80.3602 82.3372ZM79.6879 84.1439C79.884 84.1439 80.0381 84.1439 80.2902 84.1019C80.2481 84.592 80.2061 85.9085 80.2061 86.6508C80.2061 87.5051 80.2341 88.5414 80.2902 89.1156C80.0241 89.0876 79.884 89.0736 79.6599 89.0736C79.4218 89.0736 79.2678 89.0736 78.9737 89.1156C79.0437 88.3454 79.0717 87.5331 79.0717 86.5107C79.0717 85.4884 79.0437 84.7881 78.9737 84.1019C79.3098 84.1299 79.4779 84.1439 79.6879 84.1439Z"
                  fill="#31708A"
                />
                <path
                  d="M85.8501 88.1493L85.8221 88.2333C85.738 88.4434 85.71 88.5274 85.682 88.7095C85.0798 89.0876 84.6176 89.2276 83.9734 89.2276C82.4329 89.2276 81.4525 88.2193 81.4525 86.6227C81.4525 85.0262 82.3628 83.9898 83.8754 83.9898C85.2619 83.9898 86.0461 84.8161 86.0461 86.2726C86.0461 86.3286 86.0461 86.4687 86.0321 86.6087C85.556 86.5807 85.0938 86.5807 84.3655 86.5807C83.8614 86.5807 83.3292 86.5807 82.755 86.5947C82.755 86.6647 82.755 86.7348 82.755 86.7488C82.755 87.9392 83.3572 88.6675 84.3375 88.6675C84.8697 88.6675 85.3179 88.4714 85.752 88.0792L85.8641 88.1493H85.8501ZM84.0854 86.1466C84.5056 86.1466 84.5896 86.1466 84.8417 86.1326C84.8137 85.6144 84.7997 85.3903 84.7297 85.1662C84.5896 84.662 84.2955 84.3959 83.8614 84.3959C83.2171 84.3959 82.811 85.0402 82.769 86.1466C83.0351 86.1466 83.0911 86.1466 83.8193 86.1466H84.0994H84.0854Z"
                  fill="#31708A"
                />
                <path
                  d="M87.8391 84.1439C88.0632 84.1439 88.1892 84.1439 88.4273 84.1018C88.3993 84.3819 88.3993 84.424 88.3853 84.9421C88.8334 84.2979 89.3516 83.9898 90.0659 83.9898C91.1022 83.9898 91.7325 84.634 91.7325 85.7124C91.7325 85.8945 91.7325 86.1886 91.7184 86.5247C91.7184 86.8748 91.6904 87.2109 91.6904 87.519C91.6904 88.1073 91.7185 88.6955 91.7465 89.1156C91.4524 89.0876 91.2843 89.0736 91.0742 89.0736C90.8641 89.0736 90.7381 89.0736 90.472 89.1156C90.5 88.6675 90.542 87.393 90.542 86.7908C90.542 85.9925 90.514 85.6284 90.416 85.3903C90.2619 84.9702 89.9398 84.7461 89.5197 84.7461C89.0575 84.7461 88.6934 85.0262 88.5533 85.4603C88.4553 85.7964 88.4413 86.0625 88.4413 87.351C88.4413 88.0372 88.4413 88.4294 88.4833 89.1156C88.1892 89.0876 88.0632 89.0736 87.8531 89.0736C87.657 89.0736 87.517 89.0736 87.2089 89.1156C87.2789 88.5414 87.3069 87.5891 87.3069 86.5107C87.3069 85.6704 87.2789 85.0402 87.2089 84.1018C87.503 84.1299 87.657 84.1439 87.8671 84.1439"
                  fill="#31708A"
                />
                <path
                  d="M94.6731 82.4913V82.6453C94.6451 82.8974 94.6311 83.1215 94.603 83.3736C94.603 83.3736 94.603 83.7377 94.575 84.1859L95.6674 84.1578C95.6394 84.3399 95.6394 84.3679 95.6394 84.4239C95.6394 84.48 95.6394 84.508 95.6674 84.676H95.5554H95.1632H94.7431H94.589V84.8301C94.589 85.0261 94.589 85.0402 94.561 86.4687V87.407C94.561 88.0372 94.589 88.2333 94.6731 88.4434C94.7711 88.6674 94.9672 88.7795 95.2613 88.7795C95.4433 88.7795 95.5834 88.7515 95.8215 88.6814L95.7654 89.0316C95.3313 89.1856 95.0232 89.2416 94.6871 89.2416C93.8328 89.2416 93.3706 88.7375 93.3706 87.7991C93.3706 87.6871 93.3706 87.463 93.3706 87.2389C93.3986 86.1886 93.4126 85.3623 93.4126 84.648H93.2866C92.9505 84.648 92.7217 84.6574 92.6003 84.676C92.6284 84.564 92.6283 84.494 92.6283 84.3959C92.6283 84.3119 92.6284 84.2699 92.6003 84.1578L93.4266 84.1859V84.0318C93.4266 83.8917 93.4266 83.7937 93.3986 83.5836C93.3846 83.2055 93.3846 83.1495 93.3706 82.9254C93.7067 82.8414 94.1409 82.6593 94.603 82.4072L94.6731 82.4773V82.4913Z"
                  fill="#31708A"
                />
                <path
                  d="M96.8862 87.8552C97.1242 88.4574 97.6144 88.8075 98.1606 88.8075C98.7068 88.8075 99.1129 88.4714 99.1129 88.0232C99.1129 87.6311 98.9169 87.449 98.1606 87.1829C97.5164 86.9448 97.2503 86.8048 97.0262 86.6227C96.7461 86.3846 96.5781 85.9925 96.5781 85.5724C96.5781 84.606 97.3203 83.9898 98.4967 83.9898C99.0989 83.9898 99.5611 84.1018 99.9813 84.3399L99.9392 84.466C99.8972 84.592 99.8552 84.7181 99.8132 84.8301C99.7852 84.8861 99.7432 85.0262 99.6731 85.2923H99.5471C99.393 84.7041 98.9869 84.3679 98.4127 84.3679C97.9225 84.3679 97.5724 84.662 97.5724 85.0962C97.5724 85.3623 97.6984 85.5724 97.9785 85.7264C98.2026 85.8525 98.3287 85.9085 98.9309 86.1045C99.8412 86.3987 100.191 86.7768 100.191 87.463C100.191 88.4994 99.281 89.2276 98.0066 89.2276C97.4884 89.2276 97.0682 89.1436 96.55 88.9195C96.6061 88.7655 96.7041 88.2753 96.7461 87.8552H96.8582H96.8862Z"
                  fill="#31708A"
                />
                <path
                  d="M102.825 88.444C102.825 88.8645 102.489 89.2009 102.054 89.2009C101.62 89.2009 101.312 88.8645 101.312 88.444C101.312 88.0235 101.648 87.6871 102.068 87.6871C102.489 87.6871 102.825 88.0235 102.825 88.444Z"
                  fill="#31708A"
                />
                <path
                  d="M113.524 82.8135C114.196 83.4297 114.533 84.326 114.533 85.5584C114.533 86.6508 114.196 87.4911 113.496 88.1634C112.768 88.8636 111.788 89.2137 110.597 89.2137C109.407 89.2137 108.384 88.8636 107.67 88.2054C106.984 87.5752 106.662 86.7489 106.662 85.6145C106.662 84.4801 106.998 83.5417 107.712 82.8835C108.454 82.1833 109.435 81.8331 110.597 81.8331C111.76 81.8331 112.838 82.1832 113.538 82.8415M108.566 85.5444C108.566 87.4491 109.295 88.5135 110.611 88.5135C111.928 88.5135 112.628 87.4631 112.628 85.5724C112.628 84.5081 112.474 83.8078 112.096 83.3036C111.76 82.8415 111.227 82.5754 110.597 82.5754C109.309 82.5754 108.566 83.6678 108.566 85.5444Z"
                  fill="#31708A"
                />
                <path
                  d="M115.611 86.2586C115.611 85.6984 115.583 84.578 115.541 84.0458C115.919 84.0738 116.115 84.0879 116.367 84.0879C116.577 84.0879 116.717 84.0879 117.319 84.0458C117.235 85.1242 117.193 86.0766 117.193 86.7768C117.193 87.5891 117.207 87.8132 117.305 87.9672C117.417 88.1633 117.599 88.2613 117.852 88.2613C118.16 88.2613 118.384 88.1073 118.482 87.7852C118.538 87.6311 118.552 87.295 118.552 86.7488C118.552 86.6648 118.542 86.3286 118.524 85.7404C118.496 84.6901 118.496 84.6761 118.426 84.0458C118.762 84.0738 118.958 84.0879 119.308 84.0879C119.658 84.0879 119.854 84.0879 120.204 84.0458C120.176 84.508 120.134 85.6144 120.134 86.7208C120.134 87.463 120.162 88.5274 120.204 89.1156C119.896 89.0736 119.742 89.0596 119.42 89.0596C119.084 89.0596 118.874 89.0596 118.496 89.1156C118.51 88.9616 118.51 88.9196 118.524 88.5554C118.23 88.9896 117.796 89.1997 117.193 89.1997C116.703 89.1997 116.297 89.0596 116.017 88.7795C115.723 88.4994 115.583 88.0512 115.583 87.421C115.583 87.253 115.583 87.0849 115.583 86.8468V86.2446L115.611 86.2586Z"
                  fill="#31708A"
                />
                <path
                  d="M124.63 85.6563C124.434 85.4883 124.238 85.4043 123.986 85.4043C123.65 85.4043 123.383 85.6143 123.243 85.9925C123.173 86.1885 123.145 86.4686 123.145 86.9588C123.145 87.491 123.187 88.4713 123.257 89.1016C122.851 89.0595 122.641 89.0455 122.375 89.0455C122.137 89.0455 121.913 89.0455 121.479 89.1016C121.521 88.3593 121.563 87.1129 121.563 86.5667C121.563 85.9645 121.521 84.5219 121.479 84.0318C121.829 84.0598 122.039 84.0738 122.305 84.0738C122.571 84.0738 122.795 84.0738 123.173 84.0318C123.145 84.3399 123.145 84.5359 123.145 84.816V85.0541H123.215C123.509 84.3119 123.86 84.0178 124.434 84.0178C124.574 84.0178 124.672 84.0178 124.882 84.0738C124.798 84.3259 124.742 84.788 124.742 85.2222C124.742 85.3342 124.742 85.3902 124.77 85.5443L124.644 85.6423L124.63 85.6563Z"
                  fill="#31708A"
                />
                <path
                  d="M130.009 91.3767C129.555 91.3099 129.296 91.2931 128.955 91.2931C128.615 91.2931 128.388 91.3099 127.951 91.3767C127.983 90.7751 128.064 88.1852 128.064 87.2327C128.064 86.9877 128.059 86.1578 128.048 84.743C127.999 82.4037 127.999 82.3703 127.967 81.9191C128.372 81.9692 128.599 81.9859 128.907 81.9859C129.215 81.9859 129.441 81.9859 129.944 81.9191C129.879 82.3703 129.879 82.4371 129.863 82.5875C130.203 82.0695 130.738 81.8188 131.435 81.8188C133.006 81.8188 134.044 83.1055 134.044 85.0438C134.044 86.8818 133.039 88.0849 131.516 88.0849C130.819 88.0849 130.349 87.8677 129.928 87.3497V88.3356C129.928 89.5052 129.928 89.689 130.009 91.1762V91.3934V91.3767ZM129.83 84.8934C129.83 86.1967 130.268 87.0489 130.965 87.0489C131.662 87.0489 132.083 86.2636 132.083 84.9435C132.083 83.5566 131.678 82.7546 130.981 82.7546C130.252 82.7546 129.814 83.5566 129.814 84.8934"
                  fill="#31708A"
                />
                <path
                  d="M134.531 86.2586C134.531 85.6984 134.503 84.578 134.461 84.0458C134.839 84.0738 135.035 84.0879 135.287 84.0879C135.498 84.0879 135.638 84.0879 136.24 84.0458C136.156 85.1242 136.114 86.0766 136.114 86.7768C136.114 87.5891 136.128 87.8132 136.226 87.9672C136.338 88.1633 136.52 88.2613 136.772 88.2613C137.08 88.2613 137.304 88.1073 137.402 87.7852C137.458 87.6311 137.472 87.295 137.472 86.7488C137.472 86.6648 137.463 86.3286 137.444 85.7404C137.416 84.6901 137.416 84.6761 137.346 84.0458C137.682 84.0738 137.878 84.0879 138.229 84.0879C138.579 84.0879 138.775 84.0879 139.125 84.0458C139.097 84.508 139.055 85.6144 139.055 86.7208C139.055 87.463 139.083 88.5274 139.125 89.1156C138.817 89.0736 138.649 89.0596 138.341 89.0596C138.004 89.0596 137.794 89.0596 137.416 89.1156C137.444 88.9616 137.444 88.9196 137.444 88.5554C137.15 88.9896 136.716 89.1997 136.114 89.1997C135.624 89.1997 135.217 89.0596 134.923 88.7795C134.629 88.4994 134.489 88.0512 134.489 87.421C134.489 87.253 134.489 87.0849 134.489 86.8468V86.2446L134.531 86.2586Z"
                  fill="#31708A"
                />
                <path
                  d="M143.551 85.6563C143.355 85.4883 143.159 85.4043 142.907 85.4043C142.571 85.4043 142.304 85.6143 142.164 85.9925C142.094 86.1885 142.066 86.4686 142.066 86.9588C142.066 87.491 142.108 88.4713 142.178 89.1016C141.772 89.0595 141.562 89.0455 141.296 89.0455C141.058 89.0455 140.834 89.0455 140.4 89.1016C140.442 88.3593 140.484 87.1129 140.484 86.5667C140.484 85.9645 140.442 84.5219 140.4 84.0318C140.75 84.0598 140.96 84.0738 141.226 84.0738C141.492 84.0738 141.716 84.0738 142.094 84.0318C142.066 84.3399 142.066 84.5359 142.066 84.816V85.0541H142.136C142.43 84.3119 142.781 84.0178 143.355 84.0178C143.495 84.0178 143.593 84.0178 143.803 84.0738C143.719 84.3259 143.663 84.788 143.663 85.2222C143.663 85.3342 143.663 85.3902 143.691 85.5443L143.565 85.6423L143.551 85.6563Z"
                  fill="#31708A"
                />
                <path
                  d="M146.24 91.9726C145.847 91.9166 145.623 91.9026 145.329 91.9026C145.035 91.9026 144.839 91.9166 144.461 91.9726C144.489 91.4684 144.559 89.2977 144.559 88.4994C144.559 88.294 144.554 87.5984 144.545 86.4127C144.503 84.452 144.503 84.424 144.475 84.0458C144.825 84.0878 145.021 84.1018 145.287 84.1018C145.553 84.1018 145.749 84.1018 146.184 84.0458C146.128 84.424 146.128 84.48 146.114 84.606C146.408 84.1719 146.87 83.9618 147.472 83.9618C148.83 83.9618 149.727 85.0402 149.727 86.6647C149.727 88.2053 148.858 89.2136 147.542 89.2136C146.94 89.2136 146.534 89.0316 146.17 88.5974V89.4237C146.17 90.4041 146.17 90.5581 146.24 91.8046V91.9866V91.9726ZM146.086 86.5387C146.086 87.6311 146.464 88.3453 147.066 88.3453C147.668 88.3453 148.032 87.6871 148.032 86.5807C148.032 85.4183 147.682 84.7461 147.08 84.7461C146.45 84.7461 146.072 85.4183 146.072 86.5387"
                  fill="#31708A"
                />
                <path
                  d="M155.007 84.55C155.539 85.0262 155.833 85.7404 155.833 86.5807C155.833 87.421 155.581 88.0373 155.063 88.5134C154.573 88.9896 153.97 89.1997 153.116 89.1997C151.422 89.1997 150.413 88.2053 150.413 86.5527C150.413 85.7264 150.735 84.9842 151.282 84.522C151.772 84.1299 152.346 83.9478 153.13 83.9478C153.914 83.9478 154.517 84.1439 155.007 84.55ZM152.094 86.5527C152.094 87.9532 152.43 88.6255 153.13 88.6255C153.83 88.6255 154.153 87.9392 154.153 86.5947C154.153 85.2503 153.816 84.522 153.13 84.522C152.444 84.522 152.094 85.2083 152.094 86.5527Z"
                  fill="#31708A"
                />
                <path
                  d="M156.926 87.491C157.122 88.0932 157.57 88.4434 158.172 88.4434C158.648 88.4434 159.013 88.2053 159.013 87.8832C159.013 87.6171 158.859 87.505 158.172 87.2529C157.514 87.0009 157.346 86.9168 157.08 86.7348C156.716 86.4547 156.548 86.1045 156.548 85.6284C156.548 84.578 157.332 83.9338 158.606 83.9338C159.251 83.9338 159.839 84.0598 160.259 84.3119C160.119 84.7461 160.063 84.9982 159.979 85.4603L159.783 85.4883C159.629 84.9701 159.139 84.606 158.578 84.606C158.214 84.606 157.976 84.7881 157.976 85.0682C157.976 85.3903 158.172 85.5303 158.971 85.8104C159.573 86.0205 159.895 86.1746 160.119 86.3706C160.399 86.6227 160.567 87.0429 160.567 87.449C160.567 88.4854 159.615 89.1996 158.242 89.1996C157.556 89.1996 157.038 89.0736 156.562 88.7935C156.66 88.4854 156.73 87.9812 156.73 87.6311V87.491H156.926Z"
                  fill="#31708A"
                />
                <path
                  d="M165.888 87.7712C165.734 88.2193 165.72 88.2753 165.608 88.7375C165.048 89.0596 164.516 89.1997 163.872 89.1997C162.261 89.1997 161.225 88.1493 161.225 86.5527C161.225 85.7545 161.463 85.0822 161.939 84.606C162.373 84.1579 162.961 83.9478 163.745 83.9478C165.146 83.9478 166 84.7741 166 86.1046C166 86.3006 166 86.4127 165.972 86.7628C165.188 86.7488 164.754 86.7348 164.278 86.7348C163.802 86.7348 163.605 86.7348 162.919 86.7628C162.919 86.8048 162.919 86.8608 162.919 86.8748C162.919 87.7852 163.479 88.3734 164.348 88.3734C164.894 88.3734 165.286 88.1773 165.748 87.6871L165.916 87.7852L165.888 87.7712ZM163.507 86.1326C163.745 86.1326 163.9 86.1326 164.474 86.1186C164.46 85.6004 164.432 85.4323 164.376 85.2223C164.25 84.8161 163.998 84.592 163.675 84.592C163.143 84.592 162.891 85.0822 162.877 86.1186C163.129 86.1326 163.255 86.1326 163.507 86.1326Z"
                  fill="#31708A"
                />
                <path
                  d="M168.648 88.2473C168.648 88.7515 168.256 89.1436 167.737 89.1436C167.275 89.1436 166.869 88.7375 166.869 88.2473C166.869 87.7571 167.275 87.365 167.765 87.365C168.256 87.365 168.648 87.7571 168.648 88.2473Z"
                  fill="#31708A"
                />
                <path
                  d="M176 54.0333C176 55.2517 175.006 56.2601 173.787 56.2601C172.569 56.2601 171.574 55.2657 171.574 54.0333C171.574 52.8009 172.569 51.8065 173.815 51.8065C175.006 51.8065 175.986 52.8149 175.986 54.0333M171.925 54.0333C171.925 55.0837 172.765 55.938 173.787 55.938C174.81 55.938 175.636 55.0837 175.636 54.0333C175.636 52.9829 174.81 52.1146 173.815 52.1146C172.751 52.1146 171.925 52.9549 171.925 54.0333ZM175.006 55.3218H174.922C174.81 55.3218 174.698 55.3078 174.614 55.3078C174.487 55.3078 174.375 55.3078 174.235 55.3218L174.207 55.2797V55.2517C173.941 54.7616 173.857 54.6075 173.563 54.2014C173.563 54.5095 173.563 54.5655 173.591 54.8876C173.591 55.0697 173.591 55.1677 173.591 55.3218C173.465 55.3078 173.367 55.2938 173.255 55.2938C173.185 55.2938 173.101 55.2937 172.905 55.3078H172.863V55.2657C172.863 55.0697 172.891 54.4114 172.891 54.1453C172.891 53.7112 172.891 53.6692 172.877 53.193V52.8149V52.7308C173.073 52.7448 173.101 52.7449 173.227 52.7449C173.269 52.7449 173.381 52.7449 173.549 52.7449C173.675 52.7449 173.787 52.7449 173.885 52.7449C174.473 52.7449 174.768 52.9549 174.768 53.4171C174.768 53.7532 174.572 53.9913 174.221 54.0893C174.417 54.4254 174.754 54.9156 175.048 55.2797L175.006 55.3358V55.3218ZM173.563 53.9213C173.563 53.9213 173.647 53.9213 173.689 53.9213C173.969 53.9213 174.095 53.7812 174.095 53.4731C174.095 53.179 173.983 53.053 173.731 53.053C173.675 53.053 173.647 53.053 173.577 53.053V53.249V53.4451V53.8933L173.563 53.9213Z"
                  fill="#1A2549"
                />
              </svg>
            </NavLink>
          </div>
          <div className="nav_menu">
            {/* <div className="header-search">
              <form>
                <input type="text" placeholder="Search" />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="15"
                  viewBox="0 0 14 15"
                  fill="none"
                >
                  <circle
                    cx="5.87329"
                    cy="5.87329"
                    r="5.12329"
                    stroke="#367493"
                    stroke-width="1.5"
                  />
                  <line
                    x1="9.47778"
                    y1="10.0623"
                    x2="12.567"
                    y2="13.5377"
                    stroke="#367493"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </form>
            </div> */}
            <div className="headerR"></div>
            <div className="header-menu">
              <div className="mobileIcon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="90"
                  height="90"
                  viewBox="0 0 90 90"
                  fill="none"
                >
                  <g clip-path="url(#clip0_192_844)">
                    <path
                      d="M90 45C90 20.1472 69.8528 -3.05336e-06 45 -1.96701e-06C20.1472 -8.80661e-07 -3.05336e-06 20.1472 -1.96701e-06 45C-8.80661e-07 69.8528 20.1472 90 45 90C69.8528 90 90 69.8528 90 45Z"
                      fill="#31708A"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M22.981 67.0188L22.981 22.9812C22.981 21.551 24.1412 20.3907 25.5714 20.3907C27.0017 20.3907 28.162 21.551 28.162 22.9812L28.162 67.0188C28.162 68.4491 27.0017 69.6094 25.5714 69.6094C24.1412 69.6094 22.981 68.4491 22.981 67.0188ZM61.8379 67.0188L61.8379 22.9812C61.8379 21.551 62.9981 20.3907 64.4284 20.3907C65.8586 20.3907 67.0189 21.551 67.0189 22.9812L67.0189 67.0188C67.0189 68.4491 65.8586 69.6094 64.4284 69.6094C62.9981 69.6094 61.8379 68.4491 61.8379 67.0188ZM42.4094 67.0188L42.4094 22.9812C42.4094 21.551 43.5697 20.3907 44.9999 20.3907C46.4301 20.3907 47.5904 21.551 47.5904 22.9812L47.5904 67.0188C47.5904 68.4491 46.4301 69.6094 44.9999 69.6094C43.5697 69.6094 42.4094 68.4491 42.4094 67.0188Z"
                      fill="#FFFFFE"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_192_844">
                      <rect
                        width="90"
                        height="90"
                        fill="white"
                        transform="translate(0 90) rotate(-90)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <ul>
                <li className={location.pathname === "/" ? "active" : ""}>
                  <NavLink to="/">Home</NavLink>
                </li>
                <li
                  className={
                    location.pathname === "/clinical-data" ? "active" : ""
                  }
                >
                  <NavLink to="/clinical-data">Clinical Data</NavLink>
                </li>
                <li
                  className={
                    location.pathname === "/investigator-initiated-trials"
                      ? "active"
                      : ""
                  }
                >
                  <NavLink to="/investigator-initiated-trials">
                    Investigator-Initiated Trials
                  </NavLink>
                </li>
                <li
                  className={
                    location.pathname === "/medical-info-requests"
                      ? "active"
                      : ""
                  }
                >
                  <NavLink to="/medical-info-requests">
                    Medical Info Requests
                  </NavLink>
                </li>
                <li
                  className={
                    location.pathname === "/charitable-grant-request"
                      ? "active"
                      : ""
                  }
                >
                  <NavLink to="/charitable-grant-request">
                    Charitable Grant Request
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="burgerIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="90"
                height="90"
                viewBox="0 0 90 90"
                fill="none"
              >
                <g clip-path="url(#clip0_192_652)">
                  <path
                    d="M45 90C69.8528 90 90 69.8528 90 45C90 20.1472 69.8528 0 45 0C20.1472 0 0 20.1472 0 45C0 69.8528 20.1472 90 45 90Z"
                    fill="#31708A"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M22.9812 22.9814H67.0188C68.449 22.9814 69.6093 24.1417 69.6093 25.5719C69.6093 27.0022 68.449 28.1625 67.0188 28.1625H22.9812C21.5509 28.1625 20.3906 27.0022 20.3906 25.5719C20.3906 24.1417 21.5509 22.9814 22.9812 22.9814ZM22.9812 61.8384H67.0188C68.449 61.8384 69.6093 62.9986 69.6093 64.4289C69.6093 65.8591 68.449 67.0194 67.0188 67.0194H22.9812C21.5509 67.0194 20.3906 65.8591 20.3906 64.4289C20.3906 62.9986 21.5509 61.8384 22.9812 61.8384ZM22.9812 42.4099H67.0188C68.449 42.4099 69.6093 43.5702 69.6093 45.0004C69.6093 46.4306 68.449 47.5909 67.0188 47.5909H22.9812C21.5509 47.5909 20.3906 46.4306 20.3906 45.0004C20.3906 43.5702 21.5509 42.4099 22.9812 42.4099Z"
                    fill="#FFFFFE"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_192_652">
                    <rect width="90" height="90" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
