import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import clinicalDataBannerImg from "../assets/img/clinical-data-banner.svg";
import twitterImg from "../assets/img/twitter.png";
import linkedinImg from "../assets/img/linkedin.png";
import facebookImg from "../assets/img/facebook.png";
import userImg from "../assets/img/user.png";

const SinglePage = () => {
  return (
    <div className="main">
      <Header />
      <section className="clinicalbanner">
        <div className="container">
          <img src={clinicalDataBannerImg} />
        </div>
      </section>
      <section className="clinical-data">
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <div className="col3-sc">
                <h5>Share the article</h5>
                <ul>
                  <li>
                    <a href="#">
                      <img src={twitterImg} alt="Social Media" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={linkedinImg} alt="Social Media" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={facebookImg} alt="Social Media" />
                    </a>
                  </li>
                </ul>

                <div className="copylink">
                  <a href="#">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="24"
                      viewBox="0 0 20 24"
                      fill="none"
                    >
                      <path
                        d="M17.2728 23.6365H6.36375C5.64043 23.6365 4.94674 23.3491 4.43527 22.8377C3.92381 22.3262 3.63647 21.6325 3.63647 20.9092V6.36375C3.63647 5.64043 3.92381 4.94674 4.43527 4.43527C4.94674 3.92381 5.64043 3.63647 6.36375 3.63647H17.2728C17.9962 3.63647 18.6898 3.92381 19.2013 4.43527C19.7128 4.94674 20.0001 5.64043 20.0001 6.36375V20.9092C20.0001 21.6325 19.7128 22.3262 19.2013 22.8377C18.6898 23.3491 17.9962 23.6365 17.2728 23.6365ZM6.36375 5.45466C6.12264 5.45466 5.89141 5.55044 5.72092 5.72092C5.55044 5.89141 5.45466 6.12264 5.45466 6.36375V20.9092C5.45466 21.1503 5.55044 21.3815 5.72092 21.552C5.89141 21.7225 6.12264 21.8183 6.36375 21.8183H17.2728C17.5139 21.8183 17.7452 21.7225 17.9157 21.552C18.0862 21.3815 18.1819 21.1503 18.1819 20.9092V6.36375C18.1819 6.12264 18.0862 5.89141 17.9157 5.72092C17.7452 5.55044 17.5139 5.45466 17.2728 5.45466H6.36375Z"
                        fill="#676767"
                      />
                      <path
                        d="M4.54545 20H2.72727C2.00395 20 1.31026 19.7127 0.7988 19.2012C0.287337 18.6897 0 17.996 0 17.2727V2.72727C0 2.00396 0.287337 1.31026 0.7988 0.7988C1.31026 0.287337 2.00395 0 2.72727 0H13.6364C14.3597 0 15.0534 0.287337 15.5648 0.7988C16.0763 1.31026 16.3636 2.00396 16.3636 2.72727V4.54545C16.3636 4.78656 16.2679 5.01779 16.0974 5.18828C15.9269 5.35877 15.6957 5.45455 15.4545 5.45455C15.2134 5.45455 14.9822 5.35877 14.8117 5.18828C14.6412 5.01779 14.5455 4.78656 14.5455 4.54545V2.72727C14.5455 2.48617 14.4497 2.25494 14.2792 2.08445C14.1087 1.91396 13.8775 1.81818 13.6364 1.81818H2.72727C2.48617 1.81818 2.25494 1.91396 2.08445 2.08445C1.91396 2.25494 1.81818 2.48617 1.81818 2.72727V17.2727C1.81818 17.5138 1.91396 17.7451 2.08445 17.9156C2.25494 18.086 2.48617 18.1818 2.72727 18.1818H4.54545C4.78656 18.1818 5.01779 18.2776 5.18828 18.4481C5.35877 18.6186 5.45455 18.8498 5.45455 19.0909C5.45455 19.332 5.35877 19.5632 5.18828 19.7337C5.01779 19.9042 4.78656 20 4.54545 20Z"
                        fill="#676767"
                      />
                    </svg>

                    <span>Copy Link</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-9">
              <div className="col9-data">
                <h1>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit,{" "}
                  <br />
                  sed do eiusmod tempor labore et dolore.
                </h1>

                <div className="admin">
                  <div className="userIcon">
                    <img src={userImg} alt="admin" />
                  </div>
                  <div className="userTxt">
                    By <span className="adminName">Marlyn James</span>{" "}
                    <span className="adminDate">Aug 24, 2023</span>
                  </div>
                </div>

                <div className="clinicalData">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium, totam rem
                    aperiam, eaque ipsa quae ab illo inventore veritatis et
                    quasi architecto beatae vitae dicta sunt explicabo. Nemo
                    enim ipsam voluptatem quia voluptas sit aspernatur aut odit
                    aut fugit, sed quia consequuntur magni dolores eos qui
                    ratione voluptatem sequi nesciunt. Neque porro quisquam est,
                    qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
                    velit, sed quia non numquam eius modi tempora incidunt ut
                    labore et dolore magnam aliquam quaerat voluptatem. Ut enim
                    ad minima veniam, quis nostrum exercitationem ullam corporis
                    suscipit laboriosam, nisi ut aliquid ex ea commodi
                    consequatur? Quis autem vel eum iure reprehenderit qui in ea
                    voluptate velit esse quam nihil molestiae consequatur, vel
                    illum qui dolorem eum fugiat quo voluptas nulla pariatur?
                  </p>
                  <p>
                    At vero eos et accusamus et iusto odio dignissimos ducimus
                    qui blanditiis praesentium voluptatum deleniti atque
                    corrupti quos dolores et quas molestias excepturi sint
                    occaecati cupiditate non provident, similique sunt in culpa
                    qui officia deserunt mollitia animi, id est laborum et
                    dolorum fuga. Et harum quidem rerum facilis est et expedita
                    distinctio. Nam libero tempore, cum soluta nobis est
                    eligendi optio cumque nihil impedit quo minus id quod maxime
                    placeat facere possimus, omnis voluptas assumenda est, omnis
                    dolor repellendus. Temporibus autem quibusdam et aut
                    officiis debitis aut rerum necessitatibus saepe eveniet ut
                    et voluptates repudiandae sint et molestiae non recusandae.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default SinglePage;
